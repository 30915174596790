import {
  AVG_PIXEL_WIDTH_OF_CHARACTER,
  LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM,
  LEGEND_MORE_ITEMS_WIDTH,
  LEGEND_TEXT_ITEM_PADDING,
  LEGEND_WIDTH_OF_COLOR_BOX,
} from "../constants";

export function calculateNumberOfKeys({
  containerWidth,
  entries,
}: {
  containerWidth: number;
  entries: { text: string }[];
}): number {
  const availableWidth = containerWidth - LEGEND_MORE_ITEMS_WIDTH;

  let numberOfItems = 0;
  let numberOfItemsWithMaxCharacter = 0;

  let availableSpace = availableWidth;
  let availableSpaceWithMaxCharacter = availableWidth;

  const additionalWidthPerText =
    LEGEND_TEXT_ITEM_PADDING + LEGEND_WIDTH_OF_COLOR_BOX;
  for (const entry of entries) {
    const itemWidth =
      entry.text.length * AVG_PIXEL_WIDTH_OF_CHARACTER + additionalWidthPerText;

    // Calculate the width of the item, considering the maximum allowed characters for long text
    const itemWidthWithMaxCharacter =
      Math.min(entry.text.length, LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM) *
        AVG_PIXEL_WIDTH_OF_CHARACTER +
      additionalWidthPerText;

    if (
      availableSpace - itemWidth < 0 &&
      availableSpaceWithMaxCharacter - itemWidthWithMaxCharacter < 0
    ) {
      break;
    }

    if (availableSpace - itemWidth > 0) {
      numberOfItems += 1;
    }
    numberOfItemsWithMaxCharacter += 1;
    availableSpace -= itemWidth;
    availableSpaceWithMaxCharacter -= itemWidthWithMaxCharacter;
  }

  return Math.max(1, numberOfItems, numberOfItemsWithMaxCharacter);
}
