import common from "../../common.copyText";

// prettier-ignore
export default {
  ...common,
  actionUpdateAnomaly: "Update Order",
  actionViewAlerts: "View Alerts",
  actionViewEventStream: "View event stream",
  addButtonLabel: "Add",
  alertDetailModalDetectedAt: "Detected",
  alertDetailModalEventTime: "Time of anomaly",
  alertDetailModalFirstDetected: "First Detected",
  alertDetailModalTableUnavailableText: "Preview table unavailable for this alert",
  alertDetailModalTitleAnomalousSpend: "Anomalous Spend Detected",
  alertDetailModalTitleThresholdBreached: "Threshold Breached",
  alertRuleFormAccountsAll: "All Billing Accounts",
  alertRuleFormAccountsLabel: "Billing Accounts",
  alertRuleFormAiDetectionEnabledLabel: "AI Detection Enabled",
  alertRuleNameSystemAlert: "System Alert",
  alertRuleFormAndLabel: "And",
  alertRuleFormCopyNameLabel: "(copy)",
  alertRuleFormCurrencyLabel: "Currency",
  alertRuleFormDirectionLabel: "Direction",
  alertRuleFormExcludeTaxLabel: "Exclude Tax",
  alertRuleFormFiltersLabel: "Filters",
  alertRuleFormGranularityLabel: "Granularity",
  alertRuleFormGroupByHelperText: "How the model groups the data to be analyzed after it is filtered",
  alertRuleFormGroupByLabel: "Group By",
  alertRuleFormGroupByTooltipContent: "Groupings help identify the category of spend/usage driving alerts. The first value selected will appear in the summary table of alerts, with others visible in details and roll-overs.",
  alertRuleFormLookbackDaysLabel: "Lookback Days",
  alertRuleFormMinimumThresholdLabel: "Minimum Threshold",
  alertRuleFormNoGroupingsLabel: "No Groupings",
  alertRuleFormOperatorLabel: "Operator",
  alertRuleFormPercentageLabel: "Percentage",
  alertRuleFormPerLabel: "Per",
  alertRuleFormProjectIDsAll: "All Project IDs",
  alertRuleFormProjectIDsLabel: "Project IDs",
  alertRuleFormProjectNamesAll: "All Project Names",
  alertRuleFormProjectNamesLabel: "Project Names",
  alertRuleFormServicesAll: "All Services",
  alertRuleFormServicesLabel: "Services",
  alertRuleFormSkusAll: "All SKUs",
  alertRuleFormSkusLabel: "SKUs",
  alertRuleFormSubscribeNonAccountAddressLabel: "Subscribe Non-Account Address",
  alertRuleFormSubscribersAll: "All Users",
  alertRuleFormSubscribersEmailPlaceholder: "Email Address",
  alertRuleFormSubscribersIsInternalWarning: "Address belongs to User Account",
  alertRuleFormSubscribersLabel: "Subscribers",
  alertRuleFormSubscribersListAccountLabel: "Account Subscribers",
  alertRuleFormSubscribersListNonAccount: "Non-Account Subscribers",
  alertRuleFormSubscribersNone: "None",
  alertRuleHeaderAlertsTriggered: "Alerts Triggered:",
  alertRuleHeaderLastTriggered: "Last Triggered:",
  alertRuleTabLabel: "Alert Rule Configuration",
  alertRuleTableHeaderCreatedAt: "Created At",
  alertRuleTableHeaderCreatedBy: "Created By",
  alertRuleTableHeaderEventName: "Name",
  alertRuleTableHeaderEventStatus: "Status",
  alertRuleTableHeaderRuleAlertsTriggered: "Alerts Triggered",
  alertRuleTableHeaderRuleID: "Rule ID",
  alertRuleTableHeaderRuleLastTriggered: "Last Triggered",
  alertRuleTableHeaderRuleName: "Name",
  alertRuleTableHeaderRuleStatus: "Status",
  alertRuleTableTooltipActive: "Active",
  alertRuleTableTooltipInactive: "Inactive",
  alertTrackingTabLabel: "Alert Tracking",
  anomalyAlertGroupingsDropdownLabel: "Groupings",
  anomalyAlertUpdateButton: "Update Anomoaly",
  costAlertStatus_ACTIVE: "Active",
  costAlertStatus_INVESTIGATING: "Investigating",
  costAlertStatus_RESOLVED: "Resolved",
  costAlertStatus_UNRESOLVED: "Unresolved",
  costAlertTableHeader_actualValue: "Actual Value",
  costAlertTableHeader_alertedAt: "Alerted At",
  costAlertTableHeader_delta: "Delta",
  costAlertTableHeader_dimensionGrouping: "Groupings",
  costAlertTableHeader_dimensions: "Dimensions",
  costAlertTableHeader_eventType: "Event Type",
  costAlertTableHeader_expectedRange: "Expected Range",
  costAlertTableHeader_id: "id",
  costAlertTableHeader_occurredAt: "Occurred At",
  costAlertTableHeader_sourceAlertRule: "Source Alert Rule",
  costAlertTableHeader_status: "Status",
  costAlertTableHeader_alertRuleName: "Alert Rule",
  costAlertTableView: "View",
  costAlertTableHeaderRecent: "Recent Anomalies Triggered:",
  costAlertToolTipTableMessage: "To Change Order",
  costAlertToolTipTableMessageHere: "Here",
  createAlertRuleLabel: "Create Alert Rule",
  casesButtonLabel: "Cases",
  createCaseButtonLabel: "Create Case",
  directionOptionLabelDecreaseOnly: "Decreases Only",
  directionOptionLabelDefault: "Increases and Decreases",
  directionOptionLabelIncreaseOnly: "Increases Only",
  dropdownLabelCreateCase: "Create Case",
  dropdownLabelOrderGroupings: "View Order Groupings",
  dropdownLabelViewAlertDetails: "View Alert Details",
  dropdownLabelViewSourceAlertRule: "View Source Alert Rule",
  errorCreatingRuleMessage: "An error occurred while attempting to create the rule. Please try again.",
  errorUpdatingCostAlertMessage: "An error occurred while attempting to update Alert. Please try again.",
  errorUpdatingRuleMessage: "An error occurred while attempting to update the rule. Please try again.",
  eventStreamAlertDelta: "Delta",
  eventStreamAlertDimensions: "Dimensions: ",
  eventStreamAlertTriggeredTitle: "Alert Triggered",
  eventStreamAlertType: "Type: %type% ",
  eventStreamChangesText: "The following settings were updated",
  eventStreamCreatedText: "Rule created with the following settings",
  eventStreamLabelChangesAlertType: "Alert Type",
  eventStreamLabelChangesDays: "Days",
  eventStreamLabelChangesDimensions: "Dimensions",
  eventStreamLabelChangesDirection: "Detecting",
  eventStreamLabelChangesFilters: "Filters",
  eventStreamLabelChangesLookback: "Lookback",
  eventStreamLabelChangesMinimumThreshold: "Mininum Threshold",
  eventStreamLabelChangesStatus: "Status",
  eventStreamLabelTimeGranularity: "Time Granularity",
  eventStreamMeterNegativeRange: "under the expected range",
  eventStreamMeterPositiveRange: "over the expected range",
  eventStreamMeterTitleAnomaliesTriggeredAll: "# of alerts triggered",
  eventStreamMeterTitleAnomaliesTriggeredOne: "# of times triggered",
  eventStreamMeterTitleAnomalousSpend: "Total anomalous spend",
  eventStreamMeterTitlePercentage: "% of total spend",
  eventStreamRuleCreatedTitle: "Alert rule created",
  eventStreamRuleUpdatedTitle: "Alert rule Configuration updated",
  eventStreamStringifiedAlertActualValue: "Actual value",
  eventStreamStringifiedAlertExpectedValue: "Expected range",
  eventStreamStringifiedAlertThreshold: "Threshold",
  existingCaseButtonLabel: "View Case #%number%",
  goToReportButtonLabel: "Identify Root Cause",
  granularityOptionLabelDay: "Day",
  granularityOptionLabelHour: "Hour",
  granularityOptionLabelMinute: "Minute",
  granularityOptionLabelMonth: "Month",
  lookbackDaysDisabledTooltip: "Lookback Days are disabled for threshold alerts",
  lookbackDaysTooltip: "3-90 days",
  naText: "N/A",
  sideDrawerTitleCreate: "Create",
  sideDrawerTitleEdit: "Edit",
  stringifiedAlertTypeAnomalyDetected: "Anomaly Detected",
  stringifiedAlertTypeThresholdBreached: "Threshold Breached",
  stringifiedEmptyValue: "none",
  stringifiedLegacyBigQueryAnomalySourceAlert: "BigQuery Bytes Scanned",
  stringifiedLegacyBillingAnomalySourceAlert: "Category Billing Anomaly",
  successCreatingRuleMessage: "The rule has been created successfully.",
  successUpdatingCostAlertMessage: "The alert has been updated successfully.",
  successUpdatingRuleMessage: "The rule has been updated successfully.",
  systemGeneratedRuleText: "System",
  systemAlertDisableSettingTooltip: "System alerts are provided by Ternary and cannot be configured.",
  timeDistanceText: "%distance% ago",
  thresholdMinimumHelperText: "Use zero in either field to use ONLY Currency or Percentage",
  thresholdMinimumModalText: "Setting a $0 minimum dollar amount along with a relative percentage threshold can make the alerting rule overly sensitive, triggering excessive alert emails when spend is low.\n\nTo prevent this, we recommend setting a non-zero minimum.\n\nDo you want to keep $0?",
  thresholdMinimumModalTitle: "$0 Minimum Warning",
  utcTimeLabel: "UTC",
};
