export const treActions = {
  ALERT_TRE_CSV_EXPORT_LIMIT_REACHED: "ALERT_TRE_CSV_EXPORT_LIMIT_REACHED",
  CLICK_COPY_DASHBOARD_FROM_TABLE: "CLICK_COPY_DASHBOARD_FROM_TABLE",
  CLICK_SUBSCRIBE_DASHBOARD_FROM_TABLE: "CLICK_SUBSCRIBE_DASHBOARD_FROM_TABLE",
  CLICK_TRE_CREATE_DASHBOARD: "CLICK_TRE_CREATE_DASHBOARD",
  CLICK_TRE_CREATE_REPORT: "CLICK_TRE_CREATE_REPORT",
  CLICK_TRE_DASHBOARD_FAVORITE_BUTTON: "CLICK_TRE_DASHBOARD_FAVORITE_BUTTON",
  CLICK_TRE_DELETE_DASHBOARD: "CLICK_TRE_DELETE_DASHBOARD",
  CLICK_TRE_DELETE_REPORT: "CLICK_TRE_DELETE_REPORT",
  CLICK_TRE_DELETE_REPORT_FROM_TABLE: "CLICK_TRE_DELETE_REPORT_FROM_TABLE",
  CLICK_TRE_LEGEND: "CLICK_TRE_LEGEND",
  CLICK_TRE_REPORT_SUBSCRIBE_FROM_TABLE:
    "CLICK_TRE_REPORT_SUBSCRIBE_FROM_TABLE",
  CLICK_TRE_UPDATE_DASHBOARD: "CLICK_TRE_UPDATE_DASHBOARD",
  CLICK_TRE_UPDATE_REPORT: "CLICK_TRE_UPDATE_REPORT",
  CLICK_TRE_UPDATE_REPORT_FROM_TABLE: "CLICK_TRE_UPDATE_REPORT_FROM_TABLE",
  CLICK_TRE_UPDATE_REPORT_TAGS: "CLICK_TRE_UPDATE_REPORT_TAGS",
  SELECT_TRE_CHANGE_REPORT_CHART_TABLE_COLUMN_TYPE:
    "SELECT_TRE_CHANGE_REPORT_CHART_TABLE_COLUMN_TYPE",
  SELECT_TRE_CHANGE_REPORT_CHART_TYPE: "SELECT_TRE_CHANGE_REPORT_CHART_TYPE",
  SELECT_TRE_DIMENSION_NAME: "SELECT_TRE_DIMENSION_NAME",
  SELECT_TRE_FILTER_NAME: "SELECT_TRE_FILTER_NAME",
  SELECT_TRE_MEASURE_NAME: "SELECT_TRE_MEASURE_NAME",
  SELECT_TRE_SOURCE: "SELECT_TRE_SOURCE",
  TOGGLE_TRE_CHART_LEGEND_ITEM: "TOGGLE_TRE_CHART_LEGEND_ITEM",
  TOGGLE_TRE_REPORT_CREDIT_TYPES: "TOGGLE_TRE_REPORT_CREDIT_TYPES",
  VIEW_TRE_REPORT_BUILDER: "VIEW_TRE_REPORT_BUILDER",
  VIEW_TRE_REPORT_DASHBOARD: "VIEW_TRE_REPORT_DASHBOARD",
  VIEW_TRE_REPORT_FROM_TABLE: "VIEW_TRE_REPORT_FROM_TABLE",
} as const;
