import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import { LegendProps } from "recharts";
import Box from "../../ui-lib/components/Box";
import Flex from "../../ui-lib/components/Flex";
import Text from "../../ui-lib/components/Text";
import { Tooltip } from "../../ui-lib/components/Tooltip";
import copyText from "../../ui-lib/copyText";
import {
  CHART_LOWER_OPACITY,
  LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM,
  LEGEND_SINGLE_ROW_HEIGHT,
  LEGEND_WIDTH_OF_COLOR_BOX,
  REPORT_PDF_CHART_WIDTH,
} from "../constants";
import { calculateNumberOfKeys } from "../utils/ChartLegendUtils";
import { getIsDashedMeasure } from "../utils/ChartStyleUtils";
import ChartDataManager from "./ChartDataManager";

type Props = {
  align?: "center" | "left";
  dataManager: ChartDataManager;
  hoveredKey?: string | string[];
  isPieChart?: boolean;
  isServer?: boolean;
  maxKeys?: number;
  onHoverKey?: (key?: string) => void;
  onInteraction: (interaction: LegendSimple.Interaction) => void;
} & LegendProps;

type LegendEntry = {
  color: string;
  chartKey: string;
  measure?: string;
  text: string;
};

function LegendItem(props: {
  color: string;
  text: string;
  showInverseColor?: boolean;
  showLowerOpacity?: boolean;
  showEllipsisText?: boolean;
  onClick: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
}) {
  const theme = useTheme();

  const isLongerText =
    props.showEllipsisText &&
    props.text.length > LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM;
  const formattedText = isLongerText
    ? `${props.text.substring(0, LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM)}...`
    : props.text;

  const legend = (
    <StyledFlex
      alignItems="center"
      paddingHorizontal={theme.space_xs}
      paddingVertical={theme.space_xxs}
      showInverseColor={props.showInverseColor}
      style={{
        opacity: props.showLowerOpacity ? CHART_LOWER_OPACITY : 1,
      }}
      onClick={() => props.onClick()}
      onMouseOver={() => props.onMouseOver?.()}
      onMouseLeave={() => props.onMouseLeave?.()}
    >
      <Box
        background={props.color}
        borderRadius="3px"
        height={theme.fontSize_small}
        marginRight={theme.space_xxs}
        width={LEGEND_WIDTH_OF_COLOR_BOX}
      />

      <Text
        fontSize={theme.fontSize_small}
        color={
          props.showInverseColor ? theme.text_color_inverse : theme.text_color
        }
      >
        {formattedText}
      </Text>
    </StyledFlex>
  );

  if (isLongerText) {
    return <Tooltip content={props.text}>{legend}</Tooltip>;
  }

  return legend;
}

function LegendSimple(props: Props) {
  const theme = useTheme();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = React.useState(0);

  const entries: LegendEntry[] = useMemo(() => {
    const data: LegendEntry[] = [];
    if (props.payload) {
      [...props.payload]
        .reverse()
        .forEach(({ value, color = theme.background_color_disabled }) => {
          if (typeof value !== "string") return;

          const measure = props.dataManager.getMeasure(value)?.schemaName;

          if (!measure && !props.isPieChart) return;

          data.push({
            color,
            chartKey: value,
            measure,
            text: props.isPieChart
              ? value
              : getEntryText(props.dataManager, value),
          });
        });
    }
    return data;
  }, [props.payload, props.dataManager, props.isPieChart]);

  const maxKeys = useMemo(() => {
    if (props.maxKeys) return props.maxKeys;
    if (width > 0 || props.isServer) {
      const containerWidth = props.isServer ? REPORT_PDF_CHART_WIDTH : width;
      return calculateNumberOfKeys({
        containerWidth,
        entries,
      });
    }

    return entries.length;
  }, [width, props.maxKeys, entries.length]);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth);
    }
  }, []);

  const align = props.align ?? "center";
  const visibleEntries = entries.slice(0, maxKeys);
  const hiddenEntries = entries.slice(maxKeys);

  const scrollWrapper = (content: JSX.Element) =>
    props.isServer ? (
      <Box overflowY="visible">{content}</Box>
    ) : (
      <Flex
        justifyContent="center"
        maxHeight={LEGEND_SINGLE_ROW_HEIGHT}
        position="relative"
        width="100%"
      >
        <Box maxHeight="100%" width="100%" overflowX="auto">
          {content}
        </Box>
      </Flex>
    );

  function getExcludedKeysAfterToggle(chartKey: string) {
    return props.dataManager.isExcluded(chartKey)
      ? props.dataManager.excludedChartKeys.filter((key) => key !== chartKey)
      : [...props.dataManager.excludedChartKeys, chartKey];
  }

  return scrollWrapper(
    <div ref={containerRef}>
      <Flex
        flexWrap={props.isServer ? "wrap" : "nowrap"}
        justifyContent={align === "left" ? "flex-start" : "center"}
        width={"100%"}
        overflow="hidden"
        marginTop={theme.space_xs}
      >
        {visibleEntries.map((entry) => {
          const color = getIsDashedMeasure(entry.measure)
            ? `repeating-linear-gradient(45deg,${entry.color}, ${entry.color} 5px, white 5px, white 10px)`
            : entry.color;
          const showLowerOpacity = isLowerOpacity(
            props.hoveredKey,
            entry.chartKey
          );

          return (
            <LegendItem
              key={entry.chartKey}
              color={color}
              text={entry.text}
              showEllipsisText={hiddenEntries.length > 0}
              showLowerOpacity={showLowerOpacity}
              onClick={() =>
                props.onInteraction({
                  type: LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED,
                  excludedChartKeys: getExcludedKeysAfterToggle(entry.chartKey),
                })
              }
              onMouseOver={() => {
                props.onHoverKey?.(entry.chartKey);
              }}
              onMouseLeave={() => {
                props.onHoverKey?.(undefined);
              }}
            />
          );
        })}

        {!!hiddenEntries.length && (
          <Tooltip
            content={
              <Flex direction="column">
                {hiddenEntries.map((entry) => {
                  const color = getIsDashedMeasure(entry.measure)
                    ? `repeating-linear-gradient(45deg,${entry.color}, ${entry.color} 5px, white 5px, white 10px)`
                    : entry.color;

                  const showLowerOpacity = isLowerOpacity(
                    props.hoveredKey,
                    entry.chartKey
                  );

                  return (
                    <LegendItem
                      key={entry.chartKey}
                      color={color}
                      text={entry.text}
                      showEllipsisText={false}
                      showInverseColor
                      showLowerOpacity={showLowerOpacity}
                      onClick={() =>
                        props.onInteraction({
                          type: LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED,
                          excludedChartKeys: getExcludedKeysAfterToggle(
                            entry.chartKey
                          ),
                        })
                      }
                      onMouseOver={() => {
                        props.onHoverKey?.(entry.chartKey);
                      }}
                      onMouseLeave={() => {
                        props.onHoverKey?.(undefined);
                      }}
                    />
                  );
                })}
              </Flex>
            }
          >
            <Flex
              alignItems="center"
              paddingHorizontal={theme.space_xs}
              paddingVertical={theme.space_xxs}
            >
              <Box height={theme.fontSize_small} />

              <Text fontSize={theme.fontSize_small}>
                {copyText.numRowsHidden.replace(
                  "%COUNT%",
                  String(hiddenEntries.length)
                )}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </div>
  );
}

LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED =
  `LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED` as const;

interface InteractionChartExclusionChanged {
  type: typeof LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED;
  excludedChartKeys: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LegendSimple {
  export type Interaction = InteractionChartExclusionChanged;
}

export default LegendSimple;

const StyledFlex = styled(Flex)<{ showInverseColor?: boolean }>(
  ({ theme, showInverseColor }) => ({
    borderRadius: theme.borderRadius_1,
    "&:hover": {
      backgroundColor: showInverseColor
        ? theme.background_color_disabled_inverse
        : theme.background_color_disabled,
      cursor: "pointer",
    },
  })
);

function isLowerOpacity(
  hoveredKey: string | string[] | undefined,
  chartKey: string
): boolean {
  if (!hoveredKey) return false;
  if (Array.isArray(hoveredKey)) {
    return !hoveredKey.includes(chartKey);
  }
  return chartKey !== hoveredKey;
}

function getEntryText(dataManager: ChartDataManager, chartKey: string): string {
  const dimensionValues = dataManager.getDimensionValuesFromChartKey(chartKey);
  const measure = dataManager.getMeasure(chartKey);

  if (!measure) return "---";

  if (dimensionValues.length === 0) {
    return measure.displayName;
  }

  let grouping = "";

  if (dimensionValues.length === 1) {
    grouping = dimensionValues[0].value;
  } else {
    grouping = dimensionValues
      .map(({ dimension, value }) => `${dimension.displayName}: ${value}`)
      .join(" / ");
  }

  if (dataManager.measures.length === 1) {
    return grouping;
  }

  return `${measure.displayName} - ${grouping}`;
}
