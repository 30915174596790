import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/api/DataQuery";
import DataQueryExperimental from "@ternary/api-lib/analytics/api/DataQueryExperimental";
import { FORECASTED_KEY } from "@ternary/api-lib/analytics/constants";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { startOfToday } from "date-fns";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";

export function useGetForecastingTimeSeriesData(params: {
  dimensions: Dimension[];
  granularity: TimeGranularity;
  lookAheadDate: Date;
  lookBackDate: Date | null;
  measure: Measure;
  vendor: string | undefined;
}) {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const dateHelper = new DateHelper();

  const enabled = gatekeeper.hasConfiguredDataIntegration;

  const tenantID = authenticatedUser.tenant.fsDocID;

  let costDataEndDate: Date;
  let forecastedStartDate: Date;

  switch (params.granularity) {
    // exclude current month spend
    case TimeGranularity.MONTH:
      costDataEndDate = dateHelper.lastDayLastMonth();
      forecastedStartDate = dateHelper.firstOfMonth();
      break;
    // exclude current week of spend
    case TimeGranularity.WEEK:
      costDataEndDate = dateHelper.lastDayOfLastWeek();
      forecastedStartDate = dateHelper.firstDayOfWeek();
      break;
    // exclude partial day of spend
    case TimeGranularity.DAY:
      costDataEndDate = dateHelper.nDaysAgo(2);
      forecastedStartDate = dateHelper.nDaysAgo(1);
      break;
    default:
      costDataEndDate = startOfToday();
      forecastedStartDate = dateHelper.date;
      break;
  }

  //current month spend
  return useQuery({
    queryKey: ["forecastingTimeSeriesData", params],
    queryFn: async () => {
      const currentCostDataQuery = new DataQuery({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [params.lookBackDate, costDataEndDate]
          : [dateHelper.firstOfMonth(), costDataEndDate],
        dimensions: params.dimensions,
        measures: [params.measure],
        granularity: params.granularity,
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  schemaName: billingCucSchema.dimensions.vendor.schemaName,
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const currentCostDataQueryResult = await client.loadData(
        tenantID,
        currentCostDataQuery
      );

      const forecastedCostDataQuery = new DataQueryExperimental({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [params.lookBackDate, costDataEndDate]
          : [dateHelper.firstOfLastMonth(), costDataEndDate],
        dimensions: params.dimensions,
        measures: [params.measure],
        granularity: params.granularity,
        forecasting: {
          algorithm: "linear-regression",
          dateRange: [forecastedStartDate, params.lookAheadDate],
          measure: params.measure,
        },
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  schemaName: billingCucSchema.dimensions.vendor.schemaName,
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const _forecastedCostDataResult = await client.loadDataExperimental(
        tenantID,
        forecastedCostDataQuery
      );

      const timeSeriesForecastedCostDataResult = (
        _forecastedCostDataResult.forecast ?? []
      ) //Edge case for last day of month with no forecasting
        .map((entry) => {
          const value = entry[params.measure.schemaName];
          delete entry[params.measure.schemaName];

          return {
            ...entry,
            [`${params.measure.schemaName}${FORECASTED_KEY}`]: value,
          };
        });

      return [
        ...currentCostDataQueryResult.response,
        ...timeSeriesForecastedCostDataResult,
      ];
    },
    enabled,
    gcTime: ANALYTICS_QUERY_GC_TIME,
  });
}
