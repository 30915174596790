import { isValid, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import prettyBytes from "pretty-bytes";
import { UnitType } from "../enums";
import { RawValue } from "../types";
import { formatDate } from "./DateUtils";
import {
  formatCurrency,
  formatCurrencyRounded,
  formatKilograms,
  formatNumber,
  formatNumberRounded,
  formatPercentage,
} from "./NumberFormatUtils";

/**
 * Formats a measure value with the appropriate unit based on the provided options
 *
 * @param options - Configuration options for formatting
 * @param options.accounting - Whether to use accounting format for currency (optional)
 * @param options.currencyCode - The currency code to use for formatting (optional)
 * @param options.currencyMaxCharacters - Maximum characters to display for currency values (optional)
 * @param options.unit - The unit type for formatting (optional)
 * @param options.value - The numeric value to format
 * @returns A formatted string representation of the value with appropriate units
 */
export function formatMeasureValueWithUnit(options: {
  accounting?: boolean;
  currencyCode?: string;
  currencyMaxCharacters?: number;
  unit?: string;
  value: number;
}): string {
  const absValue = Math.abs(options.value);

  switch (options.unit) {
    case UnitType.BINARY_BYTES: {
      return prettyBytes(options.value, { binary: true });
    }
    case UnitType.BYTES: {
      return prettyBytes(options.value);
    }
    case UnitType.CURRENCY: {
      if (absValue > 1) {
        return formatCurrencyRounded({
          accounting: options.accounting,
          currencyCode: options.currencyCode,
          maxCharacters: options.currencyMaxCharacters,
          number: options.value,
        });
      }

      return formatCurrency({
        currencyCode: options.currencyCode,
        number: options.value,
      });
    }
    case UnitType.KILOGRAMS: {
      return formatKilograms(options.value, true);
    }
    case UnitType.PERCENTAGE: {
      if (
        options.value === 0 ||
        options.value === -Infinity ||
        options.value === Infinity
      ) {
        return formatPercentage(0);
      }

      return formatPercentage(options.value / 100);
    }
    default: {
      if (absValue > 5 && absValue < 999_000_000) {
        return formatNumberRounded(options.value);
      }

      if (absValue > 0.00001 && absValue <= 5) {
        return formatNumber(options.value, 3);
      }
      break;
    }
  }

  return options.value.toExponential(2);
}

/**
 * Formats a timestamp string according to the provided date format
 *
 * @param timestamp - The timestamp string to format
 * @param dateFormat - The desired date format pattern
 * @returns A formatted date string, or empty string if timestamp is invalid
 */
export function formatTimestamp(timestamp: string, dateFormat: string): string {
  if (isNaN(Date.parse(timestamp))) return "";

  return formatDate(toZonedTime(new Date(timestamp), "UTC"), dateFormat);
}

/**
 * Removes invalid characters (periods and colons) from a string
 *
 * @param str - The input string to process
 * @returns The string with invalid characters removed
 */
export function removeInvalidCharacters(str: string) {
  return str.replace(/[.:]/g, "");
}

/**
 * Converts a dimension value to a display format
 *
 * @param value - The raw value to convert
 * @param timeFormat - The format to use for date values
 * @returns The formatted value for display
 */
export function convertDimensionValue(value: RawValue, timeFormat: string) {
  // Handle null case first
  if (value === null) {
    return "null";
  }

  // If not a string, return as-is
  if (typeof value !== "string") {
    return value;
  }

  // Handle string cases
  return convertStringValue(value, timeFormat);
}

/**
 * Checks if a string is a valid ISO date string
 */
function isValidIsoDate(dateString: string): boolean {
  try {
    // First parse the ISO string to a Date object
    const date = parseISO(dateString);

    // Then check if the resulting Date is valid
    return isValid(date);
  } catch {
    return false;
  }
}

/**
 * Helper function to convert string values to appropriate display format
 */
function convertStringValue(value: string, timeFormat: string): string {
  // Handle numeric strings
  if (!isNaN(Number(value))) {
    return value;
  }

  // Only attempt date conversion for ISO date strings
  if (isValidIsoDate(value)) {
    const date = new Date(value);

    return formatDate(date, timeFormat);
  }

  // Return original string if no conversion applies
  return value;
}
