/**
 * @fileoverview Component for displaying validation errors for data integrations
 */

import externalLinks from "@/constants/externalLinks";
import IconAlibabaCloud from "@/ui-lib/icons/IconAlibabaCloud";
import IconAwsCloud from "@/ui-lib/icons/IconAws";
import IconAzure from "@/ui-lib/icons/IconAzure";
import IconGCP from "@/ui-lib/icons/IconGCP";
import IconMongoDb from "@/ui-lib/icons/IconMongoDb";
import IconOracleCloud from "@/ui-lib/icons/IconOracleCloud";
import IconSnowflake from "@/ui-lib/icons/IconSnowflake";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "@ternary/api-lib/analytics/utils/DateUtils";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import {
  AWSDataIntegrationEntity,
  DataIntegrationEntity,
  Validation,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { Tooltip } from "@ternary/api-lib/ui-lib/components/Tooltip";
import { isAfter, isBefore } from "date-fns";
import { uniq } from "lodash";
import React, { useState } from "react";
import copyText from "../copyText";
import { getValidationsWithErrors } from "../utils";

type ValidationList = {
  id: string;
  linkedAccountID?: string;
  name: string;
  validations: Validation[];
  providerType: CloudProviderType;
};

interface Props {
  /** List of data integrations to display validation errors for */
  integrations: DataIntegrationEntity[];
  /** Optional filters to show only specific provider types */
  validationErrorFilters: string[] | null;
  /** Callback when modal is closed */
  onClose: () => void;
  /** Callback when user interacts with validation errors */
  onInteraction: (interaction: ValidationErrorList.Interaction) => void;
}

/**
 * Component that displays validation errors for data integrations
 */
export function ValidationErrorList(props: Props): JSX.Element | null {
  const theme = useTheme();
  const [selectedValidationErrorList, setSelectedValidationErrorList] =
    useState<{
      [key: string]: number[];
    }>({});

  const integrationToValidation = props.integrations
    .reduce((accum: ValidationList[], integration) => {
      if (integration.providerType === CloudProviderType.AWS) {
        const awsIntegration = integration as AWSDataIntegrationEntity;
        if (awsIntegration.config?.linkedAccounts) {
          accum.push(
            {
              id: integration.id,
              name: integration.name,
              validations: integration.validations,
              providerType: integration.providerType,
            },
            ...awsIntegration.config.linkedAccounts
              .filter((account) =>
                account.validations.some((validation) => !validation.success)
              )
              .map((account) => ({
                id: integration.id,
                linkedAccountID: account.accountID,
                name: `${integration.name} - ${account.roleARN.split("/").pop()} - ${account.accountID}`,
                validations: account.validations,
                providerType: CloudProviderType.AWS,
              }))
          );
        } else {
          accum.push({
            id: integration.id,
            name: integration.name,
            validations: integration.validations,
            providerType: integration.providerType,
          });
        }
      } else {
        accum.push({
          id: integration.id,
          name: integration.name,
          validations: integration.validations,
          providerType: integration.providerType,
        });
      }
      return accum;
    }, [])
    .filter((validationErrors) => {
      if (props.validationErrorFilters) {
        return props.validationErrorFilters.includes(
          validationErrors.providerType
        );
      } else {
        return validationErrors;
      }
    });

  /**
   * Sorts validation errors by most recent first
   */
  function getRecentErrorValidations(errors: Validation[]) {
    return errors.sort((a, b) => {
      const aDate = a.successLastChangedAt;
      const bDate = b.successLastChangedAt;
      if (!aDate || !bDate) {
        return -1;
      }
      if (aDate.length === 0) {
        return -1;
      }
      return isBefore(new Date(aDate), new Date(bDate))
        ? 1
        : isAfter(new Date(aDate), new Date(bDate))
          ? -1
          : 0;
    });
  }

  /**
   * Handles expanding/collapsing validation error details
   */
  const handleSelectedValidationErrorList = (integrationID, index) => {
    const selectedIntegrationError =
      selectedValidationErrorList[integrationID] ?? [];
    if (
      selectedIntegrationError.length === 0 ||
      !selectedIntegrationError.includes(index)
    ) {
      setSelectedValidationErrorList({
        ...selectedValidationErrorList,
        [integrationID]: [...selectedIntegrationError, index],
      });
    } else {
      const updatedSelectedValidationErrorList =
        selectedIntegrationError.filter((validation) => validation !== index);
      setSelectedValidationErrorList({
        ...selectedValidationErrorList,
        [integrationID]: uniq(updatedSelectedValidationErrorList),
      });
    }
  };

  /**
   * Renders the banner section for an integration showing name and error count
   */
  const renderIntegrationBanner = (
    integration: ValidationList,
    errorCountText: string
  ) => {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor={theme.background_color}
        padding={theme.space_md}
        border={`1px solid ${theme.background_color_disabled}`}
      >
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            backgroundColor="white"
            border={`2px solid ${theme.background_color_disabled}`}
            borderRadius="8px"
            height={40}
            marginRight={theme.space_sm}
            justifyContent="center"
            width={40}
          >
            {providerIcon(integration.providerType)}
          </Flex>
          <Text fontWeight={600} fontSize={theme.fontSize_base}>
            <Tooltip content={integration.name}>
              {integration.name.length > 30
                ? `${integration.name.substring(0, 30)}...`
                : integration.name}
            </Tooltip>
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Box marginRight={theme.space_sm}>
            {providerDocLink(integration.providerType)}
          </Box>
          <Tooltip content={copyText.validationErrorsTooltip}>
            <Button
              onClick={() =>
                props.onInteraction({
                  type: ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED,
                  integrationID: integration.id,
                  providerType: integration.providerType,
                })
              }
              size="tiny"
            >
              <Text>{errorCountText}</Text>
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    );
  };

  /**
   * Renders individual validation error details
   */
  const renderIntegrationErrors = (
    integrationID: string,
    validation: Validation,
    index: number
  ) => {
    const selectedIntegrationError =
      selectedValidationErrorList[integrationID] ?? [];
    const isSelected = selectedIntegrationError.includes(index);
    let triggeredDate = copyText.validationErrorDateUnknown;
    if (validation.successLastChangedAt) {
      const formattedDate = formatDate(
        new Date(validation.successLastChangedAt),
        "MM/dd/yyyy"
      );
      if (formattedDate !== "0001-01-01") {
        // this handles the Go lang zero timestamp
        triggeredDate = formattedDate;
      }
    }

    return (
      <Flex
        padding={` 0px ${theme.space_lg} ${theme.space_md} ${theme.space_lg}`}
        marginTop={theme.space_md}
        gap={theme.space_xs}
        direction="column"
        borderBottom={`1px solid ${theme.border_color}`}
        key={validation.name}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          onClick={() =>
            handleSelectedValidationErrorList(integrationID, index)
          }
        >
          <Text fontSize={theme.fontSize_base} fontWeight={600} truncate>
            <Tooltip
              hide={validation.name.length < 40}
              content={`${validation.errorType}: ${validation.name}`}
            >
              {`${validation.errorType}: ${validation.name}`}
            </Tooltip>
          </Text>
          <Box>
            <Icon
              clickable
              size="lg"
              icon={isSelected ? faChevronUp : faChevronDown}
              color={theme.text_color_caption}
            />
          </Box>
        </Flex>
        <Flex>
          <Text color={theme.text_color_caption}>
            {validation.ternaryError}
          </Text>
        </Flex>
        {validation.error.length > 0 && isSelected && (
          <Flex
            justifyContent="space-between"
            backgroundColor={theme.background_color}
            padding={theme.space_md}
            borderRadius={theme.borderRadius_1}
          >
            <Box width="90%">
              <Text overflowWrap="break-word">
                {copyText.validationErrorText}
                {validation.error}
              </Text>
            </Box>
            <Tooltip content={copyText.actionCopy} placement="top">
              <Button
                size="tiny"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${copyText.validationErrorText}${validation.error}`
                  );
                  postAlert({
                    message: copyText.currentTenantCopiedToClipboard,
                    type: AlertType.SUCCESS,
                  });
                }}
                iconStart={<Icon icon={faCopy} />}
              />
            </Tooltip>
          </Flex>
        )}
        <Flex>
          <Text color={theme.text_color_caption}>
            {`${copyText.validationErrorStateChange} ${triggeredDate}`}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {integrationToValidation.map((integration, index) => {
        const isLast = index === integrationToValidation.length - 1;
        const unsuccessfulValidations = getValidationsWithErrors(
          integration.validations
        );
        const orderByTimeStamp = getRecentErrorValidations(
          unsuccessfulValidations
        );
        const errorCountText = copyText.validationErrorsCountText.replace(
          "%count%",
          unsuccessfulValidations.length.toString()
        );
        const integrationID = integration.linkedAccountID ?? integration.id;
        return (
          <Flex
            direction="column"
            marginBottom={isLast ? theme.space_xl : 0}
            key={integration.id}
          >
            {renderIntegrationBanner(integration, errorCountText)}
            {orderByTimeStamp.map((validation, index) =>
              renderIntegrationErrors(integrationID, validation, index)
            )}
          </Flex>
        );
      })}
    </>
  );
}

ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED =
  `ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED` as const;

interface InteractionConfigureButtonClicked {
  type: typeof ValidationErrorList.INTERACTION_CONFIGURE_BUTTON_CLICKED;
  integrationID: string;
  providerType: CloudProviderType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ValidationErrorList {
  export type Interaction = InteractionConfigureButtonClicked;
}

export default ValidationErrorList;

/**
 * Returns the appropriate icon component for a provider type
 */
function providerIcon(providerType) {
  let iconComponent: JSX.Element = <IconGCP size={24} />;
  switch (providerType) {
    case CloudProviderType.ALIBABA:
      iconComponent = <IconAlibabaCloud size={24} />;
      break;
    case CloudProviderType.AWS:
      iconComponent = <IconAwsCloud size={24} />;
      break;
    case CloudProviderType.AZURE:
      iconComponent = <IconAzure size={24} />;
      break;
    case CloudProviderType.MONGO_DB:
      iconComponent = <IconMongoDb size={24} />;
      break;
    case CloudProviderType.OCI:
      iconComponent = <IconOracleCloud size={24} />;
      break;
    case CloudProviderType.SNOWFLAKE:
      iconComponent = <IconSnowflake size={24} />;
      break;
    default:
      break;
  }
  return iconComponent;
}

/**
 * Returns documentation link component for a provider type
 */
function providerDocLink(providerType) {
  let link = externalLinks.readmeGCPOnboardingDocumentation;
  switch (providerType) {
    case CloudProviderType.ALIBABA:
      link = externalLinks.readmeAlibabaOnboardingDocumentation;
      break;
    case CloudProviderType.AWS:
      link = externalLinks.readmeAwsOnboardingDocumentation;
      break;
    case CloudProviderType.AZURE:
      link = externalLinks.readmeAzureOnboardingDocumentation;
      break;
    case CloudProviderType.MONGO_DB:
      link = externalLinks.readmeMongoDBOnboardingDocumentation;
      break;
    case CloudProviderType.OCI:
      link = externalLinks.readmeOCIOnboardingDocumentation;
      break;
    case CloudProviderType.SNOWFLAKE:
      link = externalLinks.readmeSnowflakeOnboardingDocumentation;
      break;
    default:
      break;
  }
  return (
    <Text>
      <a href={link} rel="noreferrer" target="_blank">
        {copyText.dataStatusDocumentation}
      </a>
    </Text>
  );
}
