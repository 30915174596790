import copyText from "../ui-lib/copyText";
import { DataSource } from "./enums";
import { awsComputeVisibilitySchema } from "./schemas/awsComputeVisibility";
import { awsKubernetesNodeCostSchema } from "./schemas/awsKubernetesNodeCost";
import { awsKubernetesNodeUsageSchema } from "./schemas/awsKubernetesNodeUsage";
import { azureComputeVisibilitySchema } from "./schemas/azureComputeVisibility";
import { azureSqlVisibilitySchema } from "./schemas/azureSqlVisibility";
import { azureStorageVisibilitySchema } from "./schemas/azureStorageVisibility";
import { billingCucSchema } from "./schemas/billingCUC";
import { carbonFootprintSchema } from "./schemas/carbonFootprint";
import { detailedBillingSchema } from "./schemas/detailedBilling";
import { ec2InstanceUtilizationSchema } from "./schemas/ec2InstanceUtilization";
import { gcpBigQueryCostSchema } from "./schemas/gcpBigQueryCost";
import { gcpBigQueryStorageSchema } from "./schemas/gcpBigQueryStorage";
import { gcpBigQueryUsageSchema } from "./schemas/gcpBigQueryUsage";
import { gcpCloudSqlCostSchema } from "./schemas/gcpCloudSqlCost";
import { gcpCloudSqlInstanceUsageSchema } from "./schemas/gcpCloudSqlInstanceUsage";
import { gcpComputeVisibilitySchema } from "./schemas/gcpComputeVisibility";
import { gcpKubernetesContainerUsageSchema } from "./schemas/gcpKubernetesContainerUsage";
import { gcpKubernetesNodeUsageSchema } from "./schemas/gcpKubernetesNodeUsage";
import { snowflakeWarehouseUsageSchema } from "./schemas/snowflakeWarehouseUsage";

// Considering Ternary fontFamily and 0.875rem font-size
export const AVG_PIXEL_WIDTH_OF_CHARACTER = 7;
export const DEFAULT_CHART_GROUPINGS_LIMIT = 25;
export const REPORT_PDF_CHART_HEIGHT = 300;
export const REPORT_PDF_CHART_WIDTH = 750;

export const COMPARISON_KEY = "Previous";
export const DEFAULT_X_AXIS_KEY = "timestamp";
export const FORECASTED_KEY = "Forecasted";
export const NOT_SHOWN_KEY = copyText.dimensionOtherNotShown;
export const PERCENT_DIFFERENCE_KEY = "percentDifference";
export const PERCENTAGE_Y_AXIS_ID = "PERCENTAGE_Y_AXIS_ID";
export const PREVIOUS_TIMESTAMP_KEY = "prevTimestamp";
export const RAW_DIFFERENCE_KEY = "rawDifference";

//
// Maps
//

/* prettier-ignore */
export const defaultMeasuresMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: Object.values(ec2InstanceUtilizationSchema.measures),
  [DataSource.AWS_COMPUTE_VISIBILITY]: Object.values(awsComputeVisibilitySchema.measures),
  [DataSource.AWS_KUBERNETES_NODE_COST]: Object.values(awsKubernetesNodeCostSchema.measures),
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: Object.values(awsKubernetesNodeUsageSchema.measures),
  [DataSource.AZURE_COMPUTE_VISIBILITY]: Object.values(azureComputeVisibilitySchema.measures),
  [DataSource.AZURE_SQL_VISIBILITY]: Object.values(azureSqlVisibilitySchema.measures),
  [DataSource.AZURE_STORAGE_VISIBILITY]: Object.values(azureStorageVisibilitySchema.measures),
  [DataSource.BIGQUERY_COST]: Object.values(gcpBigQueryCostSchema.measures),
  [DataSource.BIGQUERY_STORAGE]: Object.values(gcpBigQueryStorageSchema.measures),
  [DataSource.BIGQUERY_USAGE]: Object.values(gcpBigQueryUsageSchema.measures),
  [DataSource.BILLING]: Object.values(billingCucSchema.measures),
  [DataSource.CARBON_FOOTPRINT]: Object.values(carbonFootprintSchema.measures),
  [DataSource.CLOUD_SQL_COST]: Object.values(gcpCloudSqlCostSchema.measures),
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: Object.values(gcpCloudSqlInstanceUsageSchema.measures),
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: Object.values(gcpCloudSqlInstanceUsageSchema.measures),
  [DataSource.DETAILED_BILLING]: Object.values(detailedBillingSchema.measures),
  [DataSource.GCP_COMPUTE_VISIBILITY]: Object.values(gcpComputeVisibilitySchema.measures),
  [DataSource.KUBERNETES_CONTAINER_USAGE]: Object.values(gcpKubernetesContainerUsageSchema.measures),
  [DataSource.KUBERNETES_NODE_USAGE]: Object.values(gcpKubernetesNodeUsageSchema.measures),
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: Object.values(snowflakeWarehouseUsageSchema.measures),
};

/* prettier-ignore */
export const defaultDimensionsMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: Object.values(ec2InstanceUtilizationSchema.dimensions),
  [DataSource.AWS_COMPUTE_VISIBILITY]: Object.values(awsComputeVisibilitySchema.dimensions),
  [DataSource.AWS_KUBERNETES_NODE_COST]: Object.values(awsKubernetesNodeCostSchema.dimensions),
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: Object.values(awsKubernetesNodeUsageSchema.dimensions),
  [DataSource.AZURE_COMPUTE_VISIBILITY]: Object.values(azureComputeVisibilitySchema.dimensions),
  [DataSource.AZURE_SQL_VISIBILITY]: Object.values(azureSqlVisibilitySchema.dimensions),
  [DataSource.AZURE_STORAGE_VISIBILITY]: Object.values(azureStorageVisibilitySchema.dimensions),
  [DataSource.BIGQUERY_COST]: Object.values(gcpBigQueryCostSchema.dimensions),
  [DataSource.BIGQUERY_STORAGE]: Object.values(gcpBigQueryStorageSchema.dimensions),
  [DataSource.BIGQUERY_USAGE]: Object.values(gcpBigQueryUsageSchema.dimensions),
  [DataSource.BILLING]: Object.values(billingCucSchema.dimensions),
  [DataSource.CARBON_FOOTPRINT]: Object.values(carbonFootprintSchema.dimensions),
  [DataSource.CLOUD_SQL_COST]: Object.values(gcpCloudSqlCostSchema.dimensions),
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: Object.values(gcpCloudSqlInstanceUsageSchema.dimensions),
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: Object.values(gcpCloudSqlInstanceUsageSchema.dimensions),
  [DataSource.DETAILED_BILLING]: Object.values(detailedBillingSchema.dimensions),
  [DataSource.GCP_COMPUTE_VISIBILITY]: Object.values(gcpComputeVisibilitySchema.dimensions),
  [DataSource.KUBERNETES_CONTAINER_USAGE]: Object.values(gcpKubernetesContainerUsageSchema.dimensions),
  [DataSource.KUBERNETES_NODE_USAGE]: Object.values(gcpKubernetesNodeUsageSchema.dimensions),
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: Object.values(snowflakeWarehouseUsageSchema.dimensions),
};

export const CHART_LOWER_OPACITY = 0.25;

export const LEGEND_WIDTH_OF_COLOR_BOX = 12;
export const LEGEND_MORE_ITEMS_WIDTH = 60;
export const LEGEND_TEXT_ITEM_PADDING = 10;
export const LEGEND_MAX_CHARACTER_PER_VISIBLE_ITEM = 20;
export const LEGEND_SINGLE_ROW_HEIGHT = 33;

export const dataSourcesWithMeasures = Object.keys(
  defaultMeasuresMap
) as (keyof typeof defaultMeasuresMap)[];
