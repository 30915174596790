import { useTheme } from "@emotion/react";
import {
  faExclamationTriangle,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import Box from "../../ui-lib/components/Box";
import EmptyPlaceholder from "../../ui-lib/components/EmptyPlaceholder";
import Flex from "../../ui-lib/components/Flex";
import Icon from "../../ui-lib/components/Icon";
import { MeasureCell } from "../../ui-lib/components/Table/MeasureCell";
import Table from "../../ui-lib/components/Table/Table";
import Text from "../../ui-lib/components/Text";
import copyText from "../../ui-lib/copyText";
import {
  AVG_PIXEL_WIDTH_OF_CHARACTER,
  COMPARISON_KEY,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
} from "../constants";
import { CompareDurationType } from "../enums";
import { RawData } from "../types";
import { DateRange } from "../utils";
import {
  convertDimensionValue,
  formatTimestamp,
} from "../utils/ChartFormatUtils";
import { getFormatForGranularity } from "../utils/DateUtils";
import { formatNumber, formatPercentage } from "../utils/NumberFormatUtils";
import { getMergeState } from "../utils/StateUtils";
import { Dimension, Measure, SortRule } from "./types";

const DIMENSION_AVG_PIXEL_WIDTH_OF_CHARACTER = 4;
const MAX_ALLOWED_DATA_LENGTH = 50_000;
const MAX_ALLOWED_UI_ROWS = 100;

interface Props {
  creditTypes?: string[];
  compareDateRange?: DateRange;
  compareDurationType?: CompareDurationType | null;
  data: RawData[];
  dateRange?: DateRange;
  dimensions: Dimension[];
  footer?: boolean;
  isColumnResizable?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  maxRows?: number;
  measures: Measure[];
  pinnedColumns?: number;
  selectedMeasures?: Measure[];
  sortable?: boolean;
  sortRule?: SortRule;
  onInteraction?: (interaction: CrossSectionalDataTable.Interaction) => void;
}

interface State {
  sortRule: SortRule | undefined;
}

const columnHelper = createColumnHelper<RawData>();

export function CrossSectionalDataTable(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({ sortRule: props.sortRule });

  const sortRule: SortRule | undefined = props.sortRule ?? state.sortRule;
  const mergeState = getMergeState(setState);

  const isDataSetTooLarge = props.data.length > MAX_ALLOWED_DATA_LENGTH;

  //
  // Data Pivots
  //

  const rowObjects: RawData[] = useMemo(() => {
    if (isDataSetTooLarge) return [];

    return getRowObjects({
      data: props.data,
      dimensions: props.dimensions,
      measures: props.measures.filter((measure) => measure.schemaName),
    });
  }, [props.data]);

  const maxCharacterWidthsByColumn: { [key: string]: number } = useMemo(() => {
    // NOTE: this is used to dynamically size the table columns based on content
    return getMaxCharactersByColumn(rowObjects);
  }, [rowObjects]);

  //
  // Table Setup
  //

  function getMeasureColumns() {
    // If doing comparison, we need to know the actual selected measure to
    // do the percentage calculation.
    const selectedMeasureName = getSelectedMeasureName(props);

    const totals: [number, number] = [0, 0];

    return props.measures.map((measure) => {
      const header = props.compareDurationType
        ? getComparisonHeaderLabel(
            props.compareDateRange ?? [],
            props.dateRange ?? [],
            measure.displayName
          )
        : measure.displayName;

      const length =
        header.length > maxCharacterWidthsByColumn[measure.schemaName]
          ? header.length
          : maxCharacterWidthsByColumn[measure.schemaName];

      const width = length * AVG_PIXEL_WIDTH_OF_CHARACTER;

      return columnHelper.accessor((datum) => datum[measure.schemaName], {
        id: measure.schemaName,
        meta: { align: "right", truncate: true },
        header: header,
        cell: ({ getValue, column }) => {
          const value = getValue();

          if (
            measure.schemaName === PERCENT_DIFFERENCE_KEY &&
            typeof value === "number"
          ) {
            return formatPercentage(value);
          }

          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              unit={measure.unit}
              value={value}
            />
          );
        },
        footer: ({ table, column }) => {
          const { rows } = table.getRowModel();
          // In the case of `percentDifference` column. We need to get the
          // percent difference of the first 2 totaled rows instead of
          // totaling up all the rows.
          if (measure.schemaName === PERCENT_DIFFERENCE_KEY) {
            const decimalChange = (totals[0] - totals[1]) / totals[1];

            return formatPercentage(decimalChange);
          }

          // In every other case just total the rows like normal.
          const total = rows.reduce((sum, row) => {
            const value = row.getValue(measure.schemaName) ?? 0;

            return typeof value === "number" ? value + sum : sum;
          }, 0);

          // push these toals outside of the scope of the map so we can use them
          // on the percentDifference column
          if (measure.schemaName === selectedMeasureName) {
            totals[0] = total;
          } else if (measure.schemaName === `${selectedMeasureName}Previous`) {
            totals[1] = total;
          }

          return (
            <MeasureCell
              applyMaxCharacters
              columnID={column?.id}
              unit={measure.unit}
              value={total}
            />
          );
        },
        sortDescFirst: true,
        sortingFn: (a, b, columnID) => {
          let valueA = a.original[columnID];
          let valueB = b.original[columnID];
          if (measure.schemaName === PERCENT_DIFFERENCE_KEY) {
            if (
              typeof valueA === "number" &&
              (Number.isNaN(valueA) || !Number.isFinite(valueA))
            ) {
              valueA = 0;
            }
            if (
              typeof valueB === "number" &&
              (Number.isNaN(valueB) || !Number.isFinite(valueB))
            ) {
              valueB = 0;
            }
          }

          if (typeof valueA === "number" && typeof valueB === "number") {
            return valueA - valueB;
          }

          return 0;
        },
        sortUndefined: "last",
        size: width,
      });
    });
  }

  const dimensionColumns = props.dimensions.map((dimension) =>
    columnHelper.accessor((datum) => datum[dimension.schemaName], {
      id: dimension.schemaName,
      header: dimension.displayName,
      meta: { truncate: true },
      size:
        (dimension.schemaName.length >
        maxCharacterWidthsByColumn[dimension.schemaName]
          ? dimension.schemaName.length
          : maxCharacterWidthsByColumn[dimension.schemaName]) *
        DIMENSION_AVG_PIXEL_WIDTH_OF_CHARACTER,
      sortingFn: (rowA, rowB, columnID) => {
        const valueA = rowA.getValue(columnID);
        const valueB = rowB.getValue(columnID);

        if (typeof valueA !== "string") return -1;
        if (typeof valueB !== "string") return 1;

        if (valueA.toLowerCase() > valueB.toLowerCase()) {
          return 1;
        } else if (valueA.toLowerCase() < valueB.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      },
    })
  );

  const columns = React.useMemo(
    () => [...dimensionColumns, ...getMeasureColumns()],
    [props.data]
  );

  //
  // Event Handlers
  //

  function handleChangeSort(sortRules: SortRule[]): void {
    const newSortRule = sortRules.length
      ? { id: sortRules[0].id, desc: sortRules[0].desc }
      : undefined;

    mergeState({ sortRule: newSortRule });

    props.onInteraction?.({
      type: CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED,
      sortRule: newSortRule,
    });
  }

  //
  // JSX
  //

  const maxAllowedUIRows = props.maxRows ?? MAX_ALLOWED_UI_ROWS;

  const showRowTruncationMessage = rowObjects.length > maxAllowedUIRows;

  function renderTable() {
    if (props.isLoading || props.data.length === 0) {
      return (
        <EmptyPlaceholder
          height={400}
          loading={props.isLoading}
          icon={faTable}
          text={copyText.chartEmptyPlaceholderText}
        />
      );
    }

    return (
      <Table
        compact
        columns={columns}
        data={rowObjects}
        footer={props.footer}
        initialState={{
          pagination: { pageSize: maxAllowedUIRows },
          ...(sortRule?.id ? { sorting: [sortRule] } : {}),
        }}
        pinnedColumns={props.pinnedColumns}
        sortable={props.sortable}
        isColumnResizable={props.isColumnResizable}
        isLoading={props.isLoading}
        truncateRows
        onChangeSortBy={handleChangeSort}
      />
    );
  }

  return (
    <Box width="100%">
      <Flex
        borderRadius={theme.borderRadius_2}
        maxHeight={props.pinnedColumns !== undefined ? 600 : undefined}
        overflow="auto"
      >
        {renderTable()}
      </Flex>
      {showRowTruncationMessage && (
        <Flex alignItems="center" marginTop={theme.space_md} width="100%">
          {!props.isServer && (
            <Icon
              color={theme.secondary_color}
              icon={faExclamationTriangle}
              size="sm"
            />
          )}
          <Text
            marginBottom={theme.space_sm}
            marginLeft={theme.space_sm}
            marginRight={theme.space_xs}
          >
            {copyText.dataTableRowLimitReached}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

function getRowObjects(params: {
  data: RawData[];
  dimensions: Dimension[];
  measures: Measure[];
}) {
  const format = getFormatForGranularity();

  const rowObjects = params.data.map((datum) => {
    const newDatum = { ...datum };

    params.dimensions.forEach((dimension) => {
      newDatum[dimension.schemaName] = convertDimensionValue(
        datum[dimension.schemaName],
        format
      );
    });

    return newDatum;
  });

  return rowObjects;
}

function getComparisonHeaderLabel(
  compareDateRange: DateRange,
  dateRange: DateRange,
  measureDisplayName: string
) {
  const timestampFormat = "MM/dd";

  if (
    measureDisplayName.includes(PERCENT_DIFFERENCE_KEY) ||
    measureDisplayName.includes(RAW_DIFFERENCE_KEY)
  )
    return measureDisplayName;

  let range = dateRange;
  if (measureDisplayName.includes(COMPARISON_KEY)) {
    range = compareDateRange;
  }

  return `${measureDisplayName}
  (${formatTimestamp(range[0].toISOString(), timestampFormat)} 
  - ${formatTimestamp(range[1].toISOString(), timestampFormat)})`;
}

function getMaxCharactersByColumn(rowObjects: RawData[]) {
  return rowObjects.reduce((accum: { [key: string]: number }, datum) => {
    Object.keys(datum).forEach((key) => {
      const existingLargestLengthForKey = accum[key];
      const value = datum[key];
      let stringValue = "";

      if (value === null) {
        stringValue = "null";
      }

      if (typeof value === "string") {
        stringValue = value;
      }

      if (typeof value === "number") {
        // Limit decimals to 6 places to keep column width calculations consistent with table display
        stringValue = formatNumber(value, 6).toString();
      }

      // Note: For number type with value 0, we want to preserve largest key length
      if (value || value === 0) {
        if (stringValue.length > (existingLargestLengthForKey || 0)) {
          accum[key] = stringValue.length;
        }
      } else {
        accum[key] = stringValue.length;
      }
    });

    return accum;
  }, {});
}

function getSelectedMeasureName(props: Props): string | null {
  // Reports selected measure is user driven
  if (props.selectedMeasures && props.selectedMeasures.length === 1) {
    return props.selectedMeasures[0].schemaName;
  }
  // Dashboard widget selected measure defaults to the first column measure
  else if (props.measures && props.measures.length) {
    return props.measures[0].schemaName;
  }

  return null;
}

CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED =
  `CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED` as const;

interface InteractionSortTableClicked {
  type: typeof CrossSectionalDataTable.INTERACTION_SORT_TABLE_CLICKED;
  sortRule: SortRule | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrossSectionalDataTable {
  export type Interaction = InteractionSortTableClicked;
}

export default CrossSectionalDataTable;
