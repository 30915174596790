import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  actionMenuItemViewCharts: "View charts",
  actionMenuItemViewCommitments: "View Commitments",
  actionMenuItemViewInstances: "View instances",  
  actionMenuViewRecommendation: "View Recommendation",
  awsAllocation_amortizedCost: "Amortized Cost",
  awsAllocation_billPayerAccountId: "Payer Account ID",
  awsAllocation_commitmentARN: "Commitment ARN",
  awsAllocation_commitmentType: "Commitment Type",
  awsAllocation_countDistinctARN: "Commitment Count",
  awsAllocation_coverageType: "Coverage Type",
  awsAllocation_coveredUsageHours: "Covered Usage Hours",
  awsAllocation_effectiveCost: "Effective Cost",
  awsAllocation_instanceId: "Instance ID",
  awsAllocation_lineItemUnblendedCost: "Unblended Cost",
  awsAllocation_lineItemUsageAccountId: "Linked Account ID",
  awsAllocation_netEffectiveCost: "Net Cost",
  awsAllocation_offeringType: "Offering Type",
  awsAllocation_percentOfCommitments: "Percent Of Commitments",
  awsAllocation_region: "Region",
  awsAllocation_riNetAmortizedUpfrontCostForUsage: "RI Amortized Cost",
  awsAllocation_spNetAmortizedUpfrontCommitmentForBillingPeriod: "SP Amortized Commitment",
  awsAllocationLinkedAccountID: "Linked Account ID: %VALUE%",
  awsChartTitleCost: "Daily Cost Comparison",
  awsChartTitleUsage: "Coverage Details",
  awsChartYAxisLabel: "Normalized Hours",
  awsFilterAdvancedOptionsHide: "Hide Advanced Options",
  awsFilterAdvancedOptionsShow: "Advanced Options",
  awsFilterClear: "Clear",
  awsFilterMinSavingsPercent: "Min Savings:",
  awsFilterMinUtilPercent: "Min Utilization:",
  awsFiltersAccountID: "%TYPE% Account: %VALUE%",
  awsFiltersAccountIDs: "%TYPE% Accounts: %VALUE% and %MORE% more",
  awsFiltersAccountIDsAll: "%TYPE% Accounts: All",
  awsFiltersAccountIDsTypeLinked: "Linked",
  awsFiltersAccountIDsTypePayer: "Payer",
  awsFiltersCloudIDsTypePayer: "Cloud",
  awsFiltersAllocationType_ACCOUNT: "Allocation: Account",
  awsFiltersAllocationType_RESOURCE: "Allocation: Resource",
  awsFiltersCloudID: "Cloud ID: %VALUE%",
  awsFiltersCloudIDs: "%TYPE% Clouds: %VALUE% and %MORE% more",
  awsFiltersCloudIDsAll: "%TYPE% Clouds: All",
  awsFiltersofferingClass: "Offering Class: %VALUE%",
  awsFiltersPaymentOption: "Payment Option: %VALUE%",
  awsFiltersRecommendationLevel: "Recommendation Level: %VALUE%",
  awsFiltersSavingsPlanType: "Savings Plan Type: %VALUE%",
  awsFiltersTerm: "Term: %VALUE%",
  awsFiltersType: "Type: %VALUE%",
  awsFiltersServiceDescription: "Service: %VALUE%",
  awsInventoryTableHeader_00_type: "Type",
  awsInventoryTableHeader_01_family: "Family",
  awsInventoryTableHeader_02_instanceSize: "Instance Size",
  awsInventoryTableHeader_03_region: "Region",
  awsInventoryTableHeader_04_operatingSystem: "Operating System",
  awsInventoryTableHeader_05_instanceCount: "Instance Count",
  awsInventoryTableHeader_06_commitmentId: "Commitment ID",
  awsInventoryTableHeader_07_realizedSavings: "Realized Savings",
  awsInventoryTableHeader_08_unrealizedSavings: "Unrealized Savings",
  awsInventoryTableHeader_09_utilizationPercentage: "Utilization Percentage",
  awsInventoryTableHeader_10_status: "Status",
  awsInventoryTableHeader_11_purchaseDate: "Purchase Date",
  awsInventoryTableHeader_12_expirationDate: "Expiration Date",
  awsInventoryTableHeader_13_serviceDescription: "Service",
  awsMeter_0_count: "Total Purchase Recommendations",
  awsMeter_1_estMonthlySavings: "Estimated Monthly savings",
  awsMeter_2_estTotalSavings: "Estimated Total Savings",
  awsMeter_3_estSavingsPercentage: "Estimated Savings %",
  awsMeter_4_upfrontPayment: "Upfront Payment",
  awsMeter_5_hourlyCommitmentToPurchase: "Hourly Commitment to Purchase",
  awsMeter_5_monthlyPayment: "Monthly Payment",
  awsMeter_6_totalRICost: "Total RI Cost",
  awsMeter_6_totalSPCost: "Total Savings Plan Cost",
  awsMeterVis_0_totalCommitments: "Total Commitments",
  awsMeterVis_1_totalRealizedSavings: "Total Realized Savings",
  awsMeterVis_2_totalUnrealizedSavings: "Total Unrealized Savings",
  awsMeterVis_3_effectiveSavingsRate: "Effective Savings Rate",
  awsMeterVis_4_utilizationPercentage: "Utilization",
  awsMeterVis_5_coverage: "Commitment Coverage",
  awsReadableKey_lost: "Lost",
  awsReadableKey_saved: "Saved",
  awsRIServiceTypeLable_EC2: "EC2",
  awsRIServiceTypeLable_ELASTICACHE: "ElastiCache",
  awsRIServiceTypeLable_MEMORY_DB: "MemoryDB",
  awsRIServiceTypeLable_OPEN_SEARCH: "OpenSearch",
  awsRIServiceTypeLable_RDS: "RDS",
  awsRIServiceTypeLable_REDSHIFT: "Redshift",
  awsRITableHeader_availabilityZone: "Availability Zone",
  awsRITableHeader_averageUtilization: "Utilization %",
  awsRITableHeader_ec2InstanceType: "Instance Size",
  awsRITableHeader_estimatedBreakEvenInMonths: "Break Even (Months)",
  awsRITableHeader_estimatedMonthlySavingsAmount: "Savings $",
  awsRITableHeader_estimatedMonthlySavingsPercentage: "Savings %",
  awsRITableHeader_estimatedOnDemandCost: "On Demand $",
  awsRITableHeader_family: "Family",
  awsRITableHeaderRDS_family: "Family",
  awsRITableHeader_instanceClass: "Class",
  awsRITableHeader_instanceSize: "Size",
  awsRITableHeader_linkedAccountID: "Account ID",
  awsRITableHeader_nodeType: "Node Type",
  awsRITableHeader_normalizedUnitsToPurchase: "Normalized Units",
  awsRITableHeader_offeringClass: "Offering Class",
  awsRITableHeader_platform: "Platform",
  awsRITableHeader_productDescription: "Description",
  awsRITableHeaderRDS_productDescription: "Engine Type",
  awsRITableHeader_recommendedNumberOfInstancesToPurchase: "Quantity",
  awsRITableHeader_recurringStandardMonthlyCost: "Monthly Cost",
  awsRITableHeader_region: "Region",
  awsRITableHeaderRDS_region: "Region",
  awsRITableHeader_serviceType: "Service",
  awsRITableHeader_tenancy: "Tenancy",
  awsRITableHeader_totalCost: "Total Cost",
  awsRITableHeader_upfrontCost: "Upfront Cost",
  awsServiceType: "Service: %VALUE%",
  awsSPTableHeader_estimatedMonthlySavingsAmount: "Estimated Monthly Savings $",
  awsSPTableHeader_estimatedMonthlySavingsPercentage: "Estimated Monthly Savings %",
  awsSPTableHeader_hourlyCommitmentToPurchase: "Hourly Commitment to Purchase",
  awsSPTableHeader_estimatedSavingsPlanCost: "Estimated Savings Plans Cost",
  awsSPTableHeader_estimatedOnDemandCost: "Remaining On-Demand Cost",
  awsSPTableHeader_currentAverageHourlyOnDemandSpend: "Current Avg hourly On-Demand spend",
  awsSPTableHeader_estimatedAverageUtilization: "Estimated Avg Utilization",
  awsSPTableHeader_estimatedROI: "Estimated ROI",
  awsSPTableHeader_type: "Type",
  awsSPTableHeader_09_linkedAccountID: "Linked Account ID",
  awsSPTableHeader_offeringID: "Offering ID",
  awsSPTableHeader_10_linkedAccountID: "Linked Account ID",
  awsSPTableValueType_COMPUTE_SP: "Compute SP",
  awsSPTableValueType_EC2_INSTANCE_SP: "EC2 Instance",
  awsSPTableValueType_SAGEMAKER_SP: "SageMaker",
  awsTabLabelAllocation: "Allocation",
  awsTabLabelInventory: "Inventory",
  awsTabLabelRecommendations: "Recommendations",
  awsTableFilters_0_type: "Type",
  awsTableFilters_1_family: "Family",
  awsTableFilters_2_instanceSize: "Instance Size",
  awsTableFilters_3_region: "Region",
  awsTableFilters_4_operatingSystem: "Operating System",
  awsTableFilters_5_commitmentId: "Commitment ID",
  awsTableFilters_6_status: "Status",
  awsTableFilters_7_serviceDescription: "Service",
  awsTableTitlePurchaseSelections: "Purchase Selections",
  awsTableTitleRecommendations: "Recommendations",
  azureChartModeOptionCost: "Cost",
  azureChartModeOptionUsage: "Usage",
  azureChartTitleCoverageDetails: "Coverage Details",
  azureCommitmentCoverageType_AppService: "AppService",
  azureCommitmentCoverageType_BlockBlob: "BlockBlob",
  azureCommitmentCoverageType_Compute_Savings_Plan: "Compute_Savings_Plan",
  azureCommitmentCoverageType_MySQL: "MySQL",
  azureCommitmentCoverageType_PostgreSql: "PostgreSql",
  azureCommitmentCoverageType_RedisCache: "RedisCache",
  azureCommitmentCoverageType_VirtualMachines: "VirtualMachines",
  azureCommitmentCoverageTypeLabel_AppService: "App Service",
  azureCommitmentCoverageTypeLabel_BlockBlob: "Block Blob",
  azureCommitmentCoverageTypeLabel_Compute_Savings_Plan: "Compute SP",
  azureCommitmentCoverageTypeLabel_MySQL: "MySQL",
  azureCommitmentCoverageTypeLabel_PostgreSql: "PostgreSQL",
  azureCommitmentCoverageTypeLabel_RedisCache: "Cache for Redis",
  azureCommitmentCoverageTypeLabel_VirtualMachines: "Virtual Machines",
  azureCommitmentTypeLabelAll: "All",
  azureCommitmentTypeLabelReservation: "Reservation",
  azureCommitmentTypeLabelSavingsPlan: "Savings Plan",
  azureCommitmentVizChartReadable_onDemandUsageCost: "On Demand Cost",
  azureCommitmentVizChartReadable_onDemandUsageHours: "On Demand Usage Hours",
  azureCommitmentVizChartReadable_riAmortizedCost: "Amortized RI Cost",
  azureCommitmentVizChartReadable_riUnusedCost: "Unused RI Cost",
  azureCommitmentVizChartReadable_riUnusedHours: "RI Unused Hours",
  azureCommitmentVizChartReadable_riUsageHours: "RI Usage Hours",
  azureCommitmentVizChartReadable_spAmortizedCost: "Amortized SP Cost",
  azureCommitmentVizChartReadable_spUnusedCost: "Unused SP Cost",
  azureCommitmentVizChartReadable_spUsageHours: "SP Usage Hours",
  azureFiltersAccountID: "%TYPE% Account: %VALUE%",
  azureFiltersAccountIDsTypePayer: "Payer",
  azureFiltersCoverageType: "Service: %VALUE%",
  azureFiltersTerm: "Term: %VALUE%",
  azureFiltersType: "Type: %VALUE%",
  azureMeter_0_count: "Total Purchase Recommendations",
  azureMeter_1_estMonthlySavings: "Estimated Monthly savings",
  azureMeter_2_estTotalSavings: "Estimated Total Savings",
  azureMeter_3_estSavingsPercentage: "Estimated Savings Percentage",
  azureMeter_4_upfrontPayment: "Upfront Payment",
  azureMeter_5_hourlyCommitmentToPurchase: "Hourly Commitment to Purchase",
  azureMeter_5_monthlyPayment: "Monthly Payment",
  azureMeter_6_totalRICost: "Total RI Cost",
  azureMeter_6_totalSPCost: "Total Savings Plan Cost",
  azureMeterVis_0_totalCommitments: "Total Commitments",
  azureMeterVis_1_recurringCost: "Recurring Cost",
  azureMeterVis_2_utilizationPercentage: "Utilization",
  azureRITableHeader_commitmentCost: "Commitment $",
  azureRITableHeader_coverageType: "Coverage Type",
  azureRITableHeader_estimatedOnDemandCost: "On Demand $",
  azureRITableHeader_estimatedTotalSavings: "Savings $",
  azureRITableHeader_estimatedUtilizationPercentage: "Utilization %",
  azureRITableHeader_instanceType: "Instance Type",
  azureRITableHeader_location: "Location",
  azureRITableHeader_overageCost: "Overage Cost",
  azureRITableHeader_savingsPercentage: "Savings %",
  azureRITableHeader_totalCost: "Total Cost",
  azureSPTableHeader_commitmentCost: "Commitment $",
  azureSPTableHeader_coverageType: "Coverage Type",
  azureSPTableHeader_estimatedOnDemandCost: "On Demand Cost",
  azureSPTableHeader_estimatedTotalSavings: "Total Savings",
  azureSPTableHeader_estimatedUtilizationPercentage: "Utilization %",
  azureSPTableHeader_instanceType: "Type",
  azureSPTableHeader_location: "Location",
  azureSPTableHeader_overageCost: "Overage Cost",
  azureSPTableHeader_savingsPercentage: "Savings %",
  azureSPTableHeader_savingsPlanGranularity: "Granularity",
  azureSPTableHeader_savingsPlanTotalHours: "Total Hours",
  azureSPTableHeader_savingsPlanTotalRemainingCost: "Total Remaining Cost",
  azureSPTableHeader_totalCost: "Total Cost",
  azureTabLabelRecommendations: "Recommendations",
  azureTableHeader_avgUtilizationPercent: "Avg Util %",
  azureTableHeader_commitmentId: "Commitment ID",
  azureTableHeader_commitmentName: "Commitment Name",
  azureTableHeader_expirationDate: "Expiration Date",
  azureTableHeader_instanceCount: "Quantity",
  azureTableHeader_purchaseDate: "Purchase Date",
  azureTableHeader_region: "Region",
  azureTableHeader_skuName: "Sku Name",
  azureTableHeader_term: "Term",
  azureTableHeader_totalCost: "Total Cost",
  azureTableHeader_totalCostLong: "Total Cost (last %DAYS%)",
  azureTableHeader_type: "Type",
  azureTableHeader_upfrontCost: "Upfront Cost",
  azureTableNull: "null",
  azureTableTitleRecommendations: "Recommendations",
  azureTableTitleRecommendationsRemove: "Purchase Selections",
  chartEmptyPlaceholderText: "No Data to Display",
  commitmentLengthOption1YearLabel: "1 year",
  commitmentLengthOption3YearLabel: "3 year",
  commitmentTypeOptionResourceLabel: "Resource",
  commitmentTypeOptionSpendLabel: "Flex/Spend",
  cudCategoryActualCUD1yr: "Actual 1yr CUD Usage",
  cudCategoryActualCUD3yr: "Actual 3yr CUD Usage",
  cudCategoryCUD1Yr: "Adjusted 1yr CUD",
  cudCategoryCUD3Yr: "Adjusted 3yr CUD",
  cudCategoryOnDemand: "On Demand",
  cudChartAccountFilter: "Filter by Account",
  cudChartAxisLabelTotalCost: "Total Cost",
  cudChartLabelActual: "Actual",
  cudChartLabelAdjusted: "Adjusted",
  cudChartOnDemandBigQueryReservationApi: "BigQuery Reservation API",
  cudChartOnDemandCoverableCost: "Coverable Cost",
  cudChartOnDemandExistingCoverage: "Existing Coverage",
  cudChartOnDemandMemoryMemcached:"Cloud Memorystore for Memcached",
  cudChartOnDemandMemoryRedis: "Cloud Memorystore for Redis",
  cudChartOnDemandResourceRecommendation: "Recommendation",
  cudChartOnDemandSpendFullUtilizationRecommendation: "Full Utilization Recommendation",
  cudChartOnDemandSpendMaximumSavingsRecommendation: "Optimal Recommendation",
  cudChartOnDemandTotalSpend: "Total Spend",
  cudChartServicesFilter: "Filter by Service",
  cudChartSummary1yrCUDs: "1yr CUD Cost",
  cudChartSummary3YrCUDs: "3yr CUD Cost",
  cudChartSummaryAdditionalSavings: "estimated monthly savings",
  cudChartSummaryOnDemand: "On Demand Cost",
  cudChartTitle: "Cost Analysis",
  cudEmptyPlaceholderText: "Select Filters to Analyze Committed Usage Discounts",
  cudExplanation1yr: "1 year CUDs are a Commitment to spend a minimum amount on compute for a period of 1 year. In return for the Commitment, Google offers a %PERCENTAGE% discount off on-demand pricing.",
  cudExplanation3yr: "3 year CUDs are a Commitment to spend a minimum amount on compute for a period of 3 years. For the Commitment, Google offers a %PERCENTAGE% discount off on-demand pricing.",
  cudExplanationUnitsCPU: "Represents cores in use on a second-by-second basis.",
  cudExplanationUnitsMemory: "Represents memory in use on a second-by-second basis.",
  cudFilterDisabledProjectReason: "Project selection is disabled because CUD sharing is enabled in this Cloud.",
  cudFilterPlaceholderCloud: "Cloud",
  cudFilterPlaceholderFamily: "Family",
  cudFilterPlaceholderProject: "Project",
  cudFilterPlaceholderRegion: "Region",
  cudGraphAxisLabelDays: "Date",
  cudInventoryCommitmentTableHeader_commitmentEndTimestamp: "Term End",
  cudInventoryCommitmentTableHeader_commitmentId: "Commitment ID",
  cudInventoryCommitmentTableHeader_commitmentLength: "Term",
  cudInventoryCommitmentTableHeader_hourlyCommittedAmount: "Hourly Spend Commit",
  cudInventoryCommitmentTableHeader_owner: "Owner",
  cudInventoryCommitmentTableHeader_reservedMemoryGiB: "Reserved RAM",
  cudInventoryCommitmentTableHeader_reservedSlots: "Reserved Slots",
  cudInventoryCommitmentTableHeader_reservedVCPU: "Reserved VCPU",
  cudInventoryCommitmentTableHeader_service: "Service",
  cudInventoryCommitmentTableHeader_status: "Status",
  cudInventoryCommitmentTableNotAvailable: "N/A",
  cudInventoryCommitmentTableOwnerTooltip: "Owner represents billing account ID for spend-based commitments and project ID for resource-based commitments",
  cudInventorySectionTitle: "Existing Commitments",
  cudInventoryTableExpiredCommitmentsWarning: "%NUMBER% Commitment(s) expired fewer than 30 days ago.",
  cudInventoryTableExpiringCommitmentsWarning: "%NUMBER% Commitment(s)expiring within 60 days",
  cudInventoryTableHeader_amountSaved: "Amount Saved",
  cudInventoryTableHeader_instanceFamily: "Family",
  cudInventoryTableHeader_hourlyCommittedAmount: "Hourly Spend Commit",
  cudInventoryTableHeader_region: "Region",
  cudInventoryTableHeader_reservedMemoryGiB: "Committed RAM",
  cudInventoryTableHeader_reservedSlots: "Committed BigQuery Slots",
  cudInventoryTableHeader_reservedVCPU: "Committed VCPU",
  cudInventoryTableHeader_service: "Service",
  cudInventoryTableHeader_utilizationPercent: "Utilization%",
  cudInventoryTableNotAvailable: "N/A",
  cudInventoryTableReservedRAMTooltip: "Values shown are averaged across the selected date range.",
  cudInventoryTableReservedSlotsTooltip: "Values shown are maximum across the selected date range.",
  cudInventoryTableReservedVCPUTooltip: "Values shown are averaged across the selected date range.",
  cudInventoryTableRightsizeRecommendationTooltip: "Click to review the existing usage optimization recommendations before implementing this proposal. If implemented, they may cause this commitment recommendation to be obsolete.",
  cudInventoryTableServiceTypeFilterTooltip: "Click to filter by this service type",
  cudOptimizerGraphTitle: "Resource Usage",
  cudOptimizerSectionTitle: "CUD Optimizer",
  cudOptimizerSudCreditLabel: "Apply SUDs Credit",
  cudOptimizerTitleCPU: "CPU Last Month",
  cudOptimizerTitleMem: "Memory Last Month",
  cudPartiallyConfiguredWarning: "\"List Compute Commitments\" failed for at least 1 GCP data integration.",
  cudRecCoresLabel: "core(s)",
  cudRecModalTitle: "Purchase Recommendation",
  cudRecModalTreLink: "Click to generate a preconfigured report of relevant usage",
  cudRecommendationComputeOptimizedTooltip: "For Compute Optimized resource-based CUDs you will need to select the appropriate machine series for your currently uncovered usage. Find out more about selecting a machine series for your Compute Optimized resource based CUDs %here%",
  cudRecommendationGeneralPurposeTooltip: "For General Purpose resource-based CUDs you will need to select the appropriate machine series for your currently uncovered usage. Find out more about selecting a machine series for your General Purpose resource based CUDs %here%",
  cudRecommendationModalAccountLabel: "Account:",
  cudRecommendationModalInstanceFamilyCpuLabel: "Core CPU:",
  cudRecommendationModalProjectLabel: "Project:",
  cudRecommendationModalRamLabel: "RAM:",
  cudRecommendationModalRegionLabel: "Region:",
  cudRecommendationModalResourceBasedHeader: "Resource Based Compute CUD",
  cudRecommendationModalScopeLabel: "Scope:",
  cudRecommendationModalSpendBasedHeader: "Spend Based Compute Committed Use Discount",
  cudRecommendationModalTargetCoverageLabel: "Target Coverage:",
  cudRecommendationModalTermLabel: "Term:",
  cudRecommendationTooltipLink: "here",
  cudRecPerHourLabel: "/hour",
  cudRecScopeBillingShared: "Billing/Shared",
  cudRecScopeProject: "Project",
  cudRecSectionTitle: "Recommendations",
  cudRecSettingsFormCommitmentLengthLabel:"Commitment Length",
  cudRecSettingsFormCommitmentTypeLabel:"Commitment Type",
  cudRecSettingsFormConsoleLinksDescription:"The following link(s) will take you to the GCP console to configure CUD recommendation settings by cloud.",
  cudRecSettingsFormConsoleLinksLabel:"Configure CUD recommendations in GCP",
  cudRecSettingsFormFiltersLabel:"Filter displayed recommendations",
  cudRecSettingsFormNoValidCloudLinksMessage: "No GCP clouds configured with organization root element",
  cudRecSettingsFormTitle: "Google Commitment Preferences",
  cudRecSettingsFormWarningMessage: "Note: Configuring recommendations for 100% coverage is not recommended and will likely result in multiple recommendations and needlessly high levels of risk. Multiple CUD recommendations that will cover the same usage (billing account, service, region), should NOT be purchased within the same week to prevent over commitment, waste and negative savings.",
  cudRecSlotsLabel: "%slots% slots",
  cudSavingExplainNegative: "Estimated savings are calculated by taking your last 30 days of usage, and assuming that you continue to have the same amount of CUDs as you did at the end of the month. A negative number implies that your average CUD usage was better than your CUD usage at the end of the month. But that may or may not be the case in the following months.",
  cudSliderLabel1Yr: "1-Year CUD",
  cudSliderLabel3Yr: "3-Year CUD",
  cudSliderMarkCurrentLevel: "Current Level",
  cudSliderMarkMaximizeSavings: "Maximize Savings",
  cudVisBreadcrumbLabelAll: "All",
  cudVisCPUUsageChartAxis: "core-seconds",
  cudVisCPUUsageChartTitle: "CPU Usage",
  cudVisInventoryCommitmentPlan1Year:'1 Year',
  cudVisInventoryCommitmentPlan3Year:'3 Year',
  cudVisInventoryCommitmentStatusActive:'Active',
  cudVisInventoryCommitmentStatusCancelled:'Cancelled',
  cudVisInventoryCommitmentStatusExpired:'Expired',
  cudVisInventoryCommitmentStatusNotYetActive:'Not Yet Active',
  cudVisInventoryHeaderCommitmentStartTimestamp:'Start',
  cudVisInventoryHeaderCommitmentStatus:'Status',
  cudVisInventoryHeaderProjectID:'Project/Owner',
  cudVisRAMUsageChartAxis: "byte-seconds",
  cudVisRAMUsageChartTitle: "RAM Usage",
  cudVisRecommendationHeader_billingAccountID: "Account",
  cudVisRecommendationHeader_estimatedMonthlySavings: "Est. Monthly Savings",
  cudVisRecommendationHeader_estimatedMonthlySpend: "Est. Commitment Cost",
  cudVisRecommendationHeader_lastRefreshTime: "Last Refresh Date/Time",
  cudVisRecommendationHeader_projectID: "Project",
  cudVisRecommendationHeader_region: "Region",
  cudVisRecommendationHeader_scope: "Scope",
  cudVisRecommendationHeader_serviceType: "Service",
  cudVisRecommendationHeader_targetCommitmentInstanceFamily: "Instance Family",
  cudVisRecommendationHeader_targetCommitmentType: "Target Type",
  cudVisRecommendationHeader_targetCommitmentValue: "Target Coverage",
  cudVisRecommendationHeader_term: "Term",
  cudVisRecommendationHeader_tier: "Tier",
  cudVisRecommendationHeader_utilization: "Utilization",
  cudVisRecommendationTierTooltip: "Full utilization recommendations are commitments that will be 100% utilized. If purchased with an optimal recommendation of the same family, you will achieve maximum savings despite small periods of underutilization.",
  cudVisSpendMetersExistingCommitmentsHeader: "Existing Commitments",
  cudVisSpendMetersMonthlySavingsLabel: "Est. Monthly Savings",
  cudVisSpendMetersMonthyCostLabel: "Est. Monthly Cost",
  cudVisSpendMetersRecommendationsHeader: "Recommendations",
  cudVisSpendMetersRecsComputeTypeLabel: "Compute Type:",
  cudVisSpendMetersRecsMonthlySavingsLabel: "Est. Monthly Savings",
  cudVisSpendMetersRecsTermLabel: "Term:",
  cudVisSpendMetersRecsTotalSavingsLabel: "Est. Total Savings",
  cudVisSpendMetersTotalRecsLabel: "Total  Recommendations",
  cudVisSpendMetersUtilizationLabel: "Utilization",
  errorUpdatingUserSettings_message: "An error occurred while attempting to update your CUD recommendation preferences. Please try again.",
  exportAllInventoryButtonLabel: "Export all inventory data",
  exportUtilizationButtonLabel: "Export utilization data",
  gcpCommitmentRecommendationTierTypeFullUtilization: "Full Utilization",
  gcpCommitmentRecommendationTierTypeMaximumSavings: "Optimal",
  gcpCommitmentServiceTypeAlloyDB: "Alloy DB",
  gcpCommitmentServiceTypeBigQueryEnterprise: "BigQuery Enterprise",
  gcpCommitmentServiceTypeBigQueryEnterprisePlus: "BigQuery Enterprise Plus",
  gcpCommitmentServiceTypeCloudBigTable: "Cloud Big Table", 
  gcpCommitmentServiceTypeCloudMemoryStore: "Cloud Memory Store",
  gcpCommitmentServiceTypeCloudRun: "Cloud Run",
  gcpCommitmentServiceTypeCloudSpanner: "Cloud Spanner",
  gcpCommitmentServiceTypeCloudSql: "Cloud SQL",
  gcpCommitmentServiceTypeCloudVmWareEngine: "Cloud VMWare EnGine",
  gcpCommitmentServiceTypeComputeEngine: "Compute Engine",
  gcpCommitmentServiceTypeKubernetesEngine: "Kubernetes Engine",
  granularityOptionLabelDay: "Day",
  granularityOptionLabelHour: "Hour",
  includeFutureCommitmentsDisabledTooltip: "We require 30 days of past usage data to enable this setting.",
  includeFutureCommitmentsEnabledTooltip: "Enabling future commitments will add an additional 30 days to your selected date range and may project future usage.",
  inventoryTabLabel: "Inventory",
  optimizationsTabLabel: "Optimizations",
  successUpdatingUserSettings_message: "You have successfully updated your CUD recommendation preferences.",
  totals: "Totals",
  visibilityTabLabel: "Visibility",
};
