import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGetDimensionPreferencesByTenantID from "@/features/admin/hooks/useGetDimensionPreferencesByTenantID";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { groupOptionsByPreferences } from "@/utils/groupOptionsByPreferences";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronUp,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import {
  COMPARISON_KEY,
  FORECASTED_KEY,
} from "@ternary/api-lib/analytics/constants";
import { DataSource, TimeGranularity } from "@ternary/api-lib/analytics/enums";
import { billingCucSchema } from "@ternary/api-lib/analytics/schemas/billingCUC";
import { RawData, RawValue } from "@ternary/api-lib/analytics/types";
import StackedBarChart from "@ternary/api-lib/analytics/ui/StackedBarChart";
import { Dimension, Measure } from "@ternary/api-lib/analytics/ui/types";
import { getMergeState } from "@ternary/api-lib/analytics/utils/StateUtils";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  add,
  differenceInDays,
  format,
  isLastDayOfMonth,
  startOfDay,
  startOfMonth,
  sub,
} from "date-fns";
import { keyBy, sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useAvailableDimensionsByDataSourceV2 } from "../../../hooks/useAvailableDimensionsByDataSource";
import { DateHelper } from "../../../lib/dates";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { LoadingEllipsis } from "../../../ui-lib/components/SelectCheckbox";
import SelectDropdown from "../../../ui-lib/components/SelectDropdown";
import copyText from "../copyText";
import useGetBillingVendors from "../hooks/useGetBillingVendors";
import { useGetCostTableData } from "../hooks/useGetCostTableData";
import { useGetForecastingTimeSeriesData } from "../hooks/useGetForecastingTimeSeriesData";
import { useGetForecastingTotalsData } from "../hooks/useGetForecastingTotalsData";
import ForecastingMeters from "./ForecastingMeters";
import ForecastingTable from "./ForecastingTable";
const CHART_SECTION_HEIGHT_CLOSED = 68;
const CHART_SECTION_HEIGHT_OPEN = 450;

const defaultDimensions = [
  billingCucSchema.dimensions.category,
  billingCucSchema.dimensions.serviceDescription,
];

const LookAhead = {
  END_OF_MONTH: "END_OF_MONTH",
  D_07: "D_07",
  D_14: "D_14",
  M_01: "M_01",
  M_02: "M_02",
  M_03: "M_03",
  M_06: "M_06",
  M_12: "M_12",
  M_18: "M_18",
  M_24: "M_24",
  W_01: "W_01",
  W_02: "W_02",
  W_03: "W_03",
  W_04: "W_04",
  W_08: "W_08",
  W_12: "W_12",
  W_24: "W_24",
} as const;

type LookAhead = (typeof LookAhead)[keyof typeof LookAhead];

const LookBack = {
  NINETY_DAYS: "NINETY_DAYS",
  ONE_HUNDRED_EIGHTY_DAYS: "ONE_HUNDRED_EIGHTY_DAYS",
  ONE_HUNDRED_FIFTY_DAYS: "ONE_HUNDRED_FIFTY_DAYS",
  ONE_HUNDRED_TWENTY_DAYS: "ONE_HUNDRED_TWENTY_DAYS",
  SIX_MONTHS: "SIX_MONTHS",
  SIXTY_DAYS: "SIXTY_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
  TWELVE_MONTHS: "TWELVE_MONTHS",
  TWENTY_FOUR_MONTHS: "TWENTY_FOUR_MONTHS",
} as const;

type LookBack = (typeof LookBack)[keyof typeof LookBack];

const ChartOption = {
  CURRENT_MONTH: "CURRENT_MONTH",
  LOOKBACK: "LOOKBACK",
} as const;

type ChartOption = (typeof ChartOption)[keyof typeof ChartOption];

const GranularityOption = {
  DAY: TimeGranularity.DAY,
  MONTH: TimeGranularity.MONTH,
  WEEK: TimeGranularity.WEEK,
} as const;

type GranularityOption =
  (typeof GranularityOption)[keyof typeof GranularityOption];

const lookAheadOptionsKeyedByGranularity: {
  [Key in GranularityOption]: LookAhead[];
} = {
  [GranularityOption.DAY]: [
    LookAhead.END_OF_MONTH,
    LookAhead.D_07,
    LookAhead.D_14,
    LookAhead.M_01,
    LookAhead.M_02,
  ],
  [GranularityOption.MONTH]: [
    LookAhead.M_01,
    LookAhead.M_02,
    LookAhead.M_03,
    LookAhead.M_06,
    LookAhead.M_12,
    LookAhead.M_18,
    LookAhead.M_24,
  ],
  [GranularityOption.WEEK]: [
    LookAhead.W_01,
    LookAhead.W_02,
    LookAhead.W_03,
    LookAhead.W_04,
    LookAhead.W_08,
    LookAhead.W_12,
    LookAhead.W_24,
  ],
};

const lookBackOptionsKeyedByGranularity: {
  [Key in GranularityOption]: LookBack[];
} = {
  [GranularityOption.DAY]: [
    LookBack.THIRTY_DAYS,
    LookBack.SIXTY_DAYS,
    LookBack.NINETY_DAYS,
    LookBack.ONE_HUNDRED_TWENTY_DAYS,
    LookBack.ONE_HUNDRED_FIFTY_DAYS,
    LookBack.ONE_HUNDRED_EIGHTY_DAYS,
    LookBack.SIX_MONTHS,
    LookBack.TWELVE_MONTHS,
    LookBack.TWENTY_FOUR_MONTHS,
  ],
  [GranularityOption.MONTH]: [
    LookBack.NINETY_DAYS,
    LookBack.ONE_HUNDRED_TWENTY_DAYS,
    LookBack.ONE_HUNDRED_FIFTY_DAYS,
    LookBack.ONE_HUNDRED_EIGHTY_DAYS,
    LookBack.SIX_MONTHS,
    LookBack.TWELVE_MONTHS,
    LookBack.TWENTY_FOUR_MONTHS,
  ],
  [GranularityOption.WEEK]: [
    LookBack.THIRTY_DAYS,
    LookBack.SIXTY_DAYS,
    LookBack.NINETY_DAYS,
    LookBack.ONE_HUNDRED_TWENTY_DAYS,
    LookBack.ONE_HUNDRED_FIFTY_DAYS,
    LookBack.ONE_HUNDRED_EIGHTY_DAYS,
    LookBack.SIX_MONTHS,
    LookBack.TWELVE_MONTHS,
    LookBack.TWENTY_FOUR_MONTHS,
  ],
};

const defaultLookAheadOptionKeyedByGranularity: {
  [Key in GranularityOption]: LookAhead;
} = {
  [GranularityOption.DAY]: LookAhead.END_OF_MONTH,
  [GranularityOption.MONTH]: LookAhead.M_03,
  [GranularityOption.WEEK]: LookAhead.W_04,
};

type CsvData = {
  headers: { key: string; label: string }[];
  rows: Record<string, RawValue>[];
};

type State = {
  dimensions: Dimension[];
  granularity: GranularityOption;
  lookAheadRange: LookAhead;
  lookBackRange: LookBack;
  measure: Measure;
  selectedChartOption: ChartOption;
  showChart: boolean;
  vendor: string;
};

const initialState: State = {
  dimensions: defaultDimensions,
  granularity: GranularityOption.DAY,
  lookAheadRange: LookAhead.END_OF_MONTH,
  lookBackRange: LookBack.THIRTY_DAYS,
  measure: billingCucSchema.measures.netCost,
  selectedChartOption: ChartOption.CURRENT_MONTH,
  showChart: true,
  vendor: "ALL",
};

export function ForecastingViewContainer() {
  const activityTracker = useActivityTracker();
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();

  //
  // State
  //

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const lookBackDate = getForecastLookbackDate(state.lookBackRange);

  //
  // Queries
  //

  const { data: costTableData = [], isLoading: isLoadingCostTableData } =
    useGetCostTableData({
      dimensions: state.dimensions,
      lookBackDate: lookBackDate,
      measure: state.measure,
      vendor: state.vendor !== "ALL" ? state.vendor : undefined,
    });

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const {
    data: forecastingTimeSeriesData = [],
    isLoading: isLoadingForecastingTimeSeriesData,
  } = useGetForecastingTimeSeriesData({
    dimensions: state.dimensions,
    granularity: state.granularity,
    lookAheadDate:
      state.selectedChartOption === ChartOption.LOOKBACK
        ? getForecastLookAheadDate(state.lookAheadRange)
        : startOfMonth(add(new Date(), { months: 1 })),
    lookBackDate,
    measure: state.measure,
    vendor: state.vendor !== "ALL" ? state.vendor : undefined,
  });

  const forecastingTotals = useGetForecastingTotalsData({
    lookBackDate,
    measure: state.measure,
    vendor: state.vendor !== "ALL" ? state.vendor : undefined,
  });

  const { data: vendorData = [], isLoading: isLoadingVendors } =
    useGetBillingVendors();

  const { data: currentMtdSpend = 0 } = forecastingTotals[0];

  const { data: lastMtdSpend = 0 } = forecastingTotals[1];

  const { data: lastMonthTotalSpend = 0 } = forecastingTotals[2];

  const { data: last3MonthAverage = 0 } = forecastingTotals[3];

  const { data: currentMtdForecastedSpend = 0 } = forecastingTotals[4];

  const isLoadingTotals = forecastingTotals.some((result) => result.isLoading);

  //
  // Render
  //

  const csvData = useMemo(
    () => getCsvData(state.dimensions, state.measure, costTableData ?? []),
    [costTableData]
  );

  const filteredForecastTimeSeries = useMemo(() => {
    if (state.selectedChartOption === ChartOption.LOOKBACK)
      return forecastingTimeSeriesData;

    const dateHelper = new DateHelper();

    const lastDayOfLastMonth = dateHelper.lastDayLastMonth();
    const lastDayOfThisMonth = dateHelper.lastDayThisMonth();

    return (forecastingTimeSeriesData ?? []).filter((datum) => {
      if (typeof datum["timestamp"] === "string") {
        const timestampDate = new Date(datum["timestamp"]);
        const check =
          timestampDate >= lastDayOfLastMonth &&
          timestampDate <= lastDayOfThisMonth;
        return check;
      }
    });
  }, [forecastingTimeSeriesData, state.selectedChartOption]);

  const _vendorOptions = sortBy(
    vendorData.map((datum) => ({
      label: datum.vendor,
      value: datum.vendor,
      onClick: (value) => {
        mergeState({ vendor: value });

        activityTracker.captureAction(actions.SELECT_FORECASTING_VENDOR, {
          vendor: value,
        });
      },
    })),
    "label"
  );

  const vendorOptions = [
    {
      label: "All",
      value: "ALL",
      onClick: (value) => {
        mergeState({ vendor: value });

        activityTracker.captureAction(actions.SELECT_FORECASTING_VENDOR, {
          vendor: value,
        });
      },
    },
    ..._vendorOptions,
  ];

  const selectedVendorOption = vendorOptions.find(
    (option) => option.value === state.vendor
  );

  const billingDimensions = useAvailableDimensionsByDataSourceV2(
    DataSource.BILLING
  );

  const dimensionsKeyedBySchemaName = keyBy(billingDimensions, "schemaName");

  const dimensionOptions = billingDimensions.map((dimension) => {
    const dimensionLabel =
      dimension.displayName.length > 50
        ? `${dimension.displayName.slice(0, 50)}...${dimension.displayName.slice(dimension.displayName.length - 10, -1)}`
        : dimension.displayName;

    return {
      label: dimensionLabel,
      value: dimension.schemaName,
    };
  });

  const selectedDimensionOptions = dimensionOptions.filter((option) =>
    state.dimensions.some((dimension) => dimension.schemaName === option.value)
  );

  const groupedDimensions = groupOptionsByPreferences(
    dimensionOptions,
    dimensionPreferences,
    DataSource.BILLING
  );

  const measureOptions = [
    billingCucSchema.measures.amortizedCost,
    billingCucSchema.measures.cost,
    billingCucSchema.measures.indirectCost,
    billingCucSchema.measures.netAmortizedCost,
    billingCucSchema.measures.netCost,
  ].map((measure) => ({
    label: copyText[`measureLabel_${measure.schemaName}`],
    value: measure.schemaName,
    onClick: (value) => {
      mergeState({ measure });
      activityTracker.captureAction(actions.SELECT_FORECASTING_MEASURE, {
        measure: value,
      });
    },
  }));

  const selectedMeasureOption = measureOptions.find(
    (option) => option.value === state.measure.schemaName
  );

  const combinedCostMeasures = [
    state.measure,
    {
      ...state.measure,
      displayName: `${state.measure.displayName} ${FORECASTED_KEY}`,
      schemaName: `${state.measure.schemaName}${FORECASTED_KEY}`,
    },
  ];

  const lookAheadOptions = lookAheadOptionsKeyedByGranularity[
    state.granularity
  ].map((lookAheadRange) => ({
    label: safeCopyText(`lookAheadRangeLabel_${lookAheadRange}`),
    value: lookAheadRange,
    onClick: () => {
      mergeState({ lookAheadRange, selectedChartOption: ChartOption.LOOKBACK });
      activityTracker.captureAction(actions.SELECT_FORECASTING_LOOKBACK_RANGE, {
        range: lookAheadRange,
      });
    },
  }));

  const selectedLookAheadOption = lookAheadOptions.find(
    (option) => option.value === state.lookAheadRange
  );

  const lookBackOptions = lookBackOptionsKeyedByGranularity[
    state.granularity
  ].map((lookBackRange) => ({
    label: safeCopyText(`lookBackRangeLabel_${lookBackRange}`),
    value: lookBackRange,
    onClick: () => {
      mergeState({ lookBackRange, selectedChartOption: ChartOption.LOOKBACK });
      activityTracker.captureAction(actions.SELECT_FORECASTING_LOOKBACK_RANGE, {
        range: lookBackRange,
      });
    },
  }));

  const selectedLookBackOption = lookBackOptions.find(
    (option) => option.value === state.lookBackRange
  );

  const chartOptions = [ChartOption.CURRENT_MONTH, ChartOption.LOOKBACK].map(
    (selectedChartOption) => ({
      label: safeCopyText(`forecastingChartOption_${selectedChartOption}`),
      value: selectedChartOption,
      onClick: () => {
        mergeState({ selectedChartOption });
        activityTracker.captureAction(
          actions.SELECT_FORECASTING_CHART_TYPE_OPTION,
          { chartOption: selectedChartOption }
        );
      },
    })
  );

  const selectedChartOption = chartOptions.find(
    (option) => option.value === state.selectedChartOption
  );

  const granularityOptions = [
    GranularityOption.DAY,
    GranularityOption.WEEK,
    GranularityOption.MONTH,
  ].map((granularity) => ({
    label: safeCopyText(`forecastingGranularityOption_${granularity}`),
    value: granularity,
    onClick: () => {
      const nextLookAhead =
        defaultLookAheadOptionKeyedByGranularity[granularity];

      const lookBackOptions = lookBackOptionsKeyedByGranularity[granularity];

      const nextLookBack = lookBackOptions.includes(state.lookBackRange)
        ? state.lookBackRange
        : lookBackOptions[0];

      mergeState({
        granularity,
        lookAheadRange: nextLookAhead,
        lookBackRange: nextLookBack,
        selectedChartOption: ChartOption.LOOKBACK,
      });
    },
  }));

  const selectedGranularityOption = granularityOptions.find(
    (option) => option.value === state.granularity
  );

  const firstForecastedData = filteredForecastTimeSeries?.find((e) => {
    return (
      typeof e?.[`${selectedMeasureOption?.value}${FORECASTED_KEY}`] ===
      "number"
    );
  });

  return (
    <Box>
      <Text appearance="h2" marginBottom={theme.space_md}>
        {copyText.forecastingPageTitle}
      </Text>
      <Flex
        alignItems=" center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex>
          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelVendor}</Text>
            <Dropdown
              options={vendorOptions}
              selectedOption={selectedVendorOption}
            >
              <Flex alignItems="center" justifyContent="center" height={24}>
                {isLoadingVendors ? (
                  <LoadingEllipsis margin={theme.space_xxs} />
                ) : (
                  <Flex alignItems="center">
                    <Text bold marginRight={theme.space_xs}>
                      {selectedVendorOption?.label}
                    </Text>
                    <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
                  </Flex>
                )}
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1} />
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelGroupings}</Text>
            <SelectDropdown
              closeOnSubmit
              hideSelectedOptions={false}
              isMulti
              options={groupedDimensions}
              selectedValues={state.dimensions.map(
                (dimension) => dimension.schemaName
              )}
              onChange={(options) => {
                const dimensions = options.map(
                  (option) => dimensionsKeyedBySchemaName[option]
                );

                mergeState({ dimensions });

                activityTracker.captureAction(
                  actions.SELECT_FORECASTING_GROUPINGS,
                  { dimensions: options }
                );
              }}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs} truncate={150}>
                  {selectedDimensionOptions.length > 0
                    ? selectedDimensionOptions
                        .map((dimension) => dimension.label)
                        .join(", ")
                    : copyText.groupingsDropdownPlaceholder}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </SelectDropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelMeasure}</Text>
            <Dropdown
              options={measureOptions}
              selectedOption={selectedMeasureOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedMeasureOption?.label}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelGranularity}</Text>
            <Dropdown
              options={granularityOptions}
              selectedOption={selectedGranularityOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedGranularityOption?.label ??
                    copyText.forecastingGranularityOption_default}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelLookbackRange}</Text>
            <Dropdown
              options={lookBackOptions}
              selectedOption={selectedLookBackOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedLookBackOption?.label ??
                    copyText.lookbackRangeLabel_default}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>

          <Box marginRight={theme.space_xxl}>
            <Text>{copyText.dropdownLabelLookAheadRange}</Text>
            <Dropdown
              options={lookAheadOptions}
              selectedOption={selectedLookAheadOption}
            >
              <Flex alignItems="center" height={24}>
                <Text bold marginRight={theme.space_xs}>
                  {selectedLookAheadOption?.label ??
                    copyText.lookAheadRangeLabel_default}
                </Text>
                <Icon color="white" icon={faChevronDown} size="2xs"></Icon>
              </Flex>
            </Dropdown>
            <Box backgroundColor={theme.text_color} height={1}></Box>
          </Box>
        </Flex>

        <CSVLink
          data={csvData.rows}
          filename={`forecasting-${format(new Date(), "MM-dd-yyyy")}`}
          headers={csvData.headers}
        >
          <Button
            disabled={isLoadingCostTableData}
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
            onClick={() => {
              activityTracker.captureAction(actions.CLICK_FORECASTING_EXPORT);
            }}
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Flex>
      <ForecastingMeters
        isLoading={isLoadingTotals}
        currentMtdForecastedSpend={currentMtdForecastedSpend}
        currentMtdSpend={currentMtdSpend}
        last3MonthAverage={last3MonthAverage}
        lastMonthTotalSpend={lastMonthTotalSpend}
        lastMtdSpend={lastMtdSpend}
      />
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={
          state.showChart
            ? CHART_SECTION_HEIGHT_OPEN
            : CHART_SECTION_HEIGHT_CLOSED
        }
        marginBottom={theme.space_lg}
        padding={theme.space_md}
        transition="height 400ms"
      >
        <Flex alignItems="center">
          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_sm}
            width={"100%"}
          >
            <Flex>
              <Button
                iconEnd={
                  <Icon icon={state.showChart ? faChevronDown : faChevronUp} />
                }
                marginRight={theme.space_sm}
                size="small"
                onClick={() => {
                  setState((currentState) => ({
                    ...currentState,
                    showChart: !currentState.showChart,
                  }));

                  if (state.showChart) {
                    activityTracker.captureAction(
                      actions.CLICK_FORECASTING_CHART_COLLAPSE
                    );
                  } else {
                    activityTracker.captureAction(
                      actions.CLICK_FORECASTING_CHART_EXPAND
                    );
                  }
                }}
              />
              <Text appearance="h3">{copyText.chartTitleTotalSpend}</Text>
            </Flex>

            <Dropdown
              options={chartOptions}
              selectedOption={selectedChartOption}
            >
              <Button secondary size="small">
                {selectedChartOption?.label}
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
        <Box
          height="90%"
          opacity={state.showChart ? 1 : 0}
          transition="opacity 300ms"
        >
          <StackedBarChart
            data={filteredForecastTimeSeries}
            dimensions={state.dimensions}
            isLoading={isLoadingForecastingTimeSeriesData}
            isForecastingMode
            measures={combinedCostMeasures}
            showLegend
            showTooltip
            timeSeriesGranularity={state.granularity}
            firstForecastedTimestamp={firstForecastedData?.timestamp}
            xAxisKey="timestamp"
          />
        </Box>
      </Box>
      <ForecastingTable
        data={costTableData}
        dimensions={state.dimensions}
        measure={state.measure}
        isLoading={isLoadingCostTableData}
      />
    </Box>
  );
}

function getForecastLookbackDate(range: LookBack): Date {
  const lookBackDays = getLookbackRangeInDays(range);
  let pastDate = startOfDay(new Date());

  pastDate = sub(pastDate, { days: lookBackDays });

  if (isLastDayOfMonth(pastDate)) {
    pastDate = sub(pastDate, { days: 1 });
  }

  return pastDate;
}

function getForecastLookAheadDate(range: LookAhead): Date {
  const today = startOfDay(new Date());

  switch (range) {
    case LookAhead.END_OF_MONTH:
      return startOfMonth(add(today, { months: 1 }));
    case LookAhead.D_07:
      return add(today, { days: 7 });
    case LookAhead.D_14:
      return add(today, { days: 14 });
    case LookAhead.M_01:
      return add(today, { months: 1 });
    case LookAhead.M_02:
      return add(today, { months: 2 });
    case LookAhead.M_03:
      return add(today, { months: 3 });
    case LookAhead.M_06:
      return add(today, { months: 6 });
    case LookAhead.M_12:
      return add(today, { months: 12 });
    case LookAhead.M_18:
      return add(today, { months: 18 });
    case LookAhead.M_24:
      return add(today, { months: 24 });
    case LookAhead.W_01:
      return add(today, { weeks: 1 });
    case LookAhead.W_02:
      return add(today, { weeks: 2 });
    case LookAhead.W_03:
      return add(today, { weeks: 3 });
    case LookAhead.W_04:
      return add(today, { weeks: 4 });
    case LookAhead.W_08:
      return add(today, { weeks: 8 });
    case LookAhead.W_12:
      return add(today, { weeks: 12 });
    case LookAhead.W_24:
      return add(today, { weeks: 24 });
  }
}

function getLookbackRangeInDays(range: LookBack): number {
  let days = 30;
  switch (range) {
    case LookBack.THIRTY_DAYS: {
      days = 30;
      break;
    }
    case LookBack.SIXTY_DAYS: {
      days = 60;
      break;
    }
    case LookBack.NINETY_DAYS: {
      days = 90;
      break;
    }
    case LookBack.ONE_HUNDRED_TWENTY_DAYS: {
      days = 120;
      break;
    }
    case LookBack.ONE_HUNDRED_FIFTY_DAYS: {
      days = 150;
      break;
    }
    case LookBack.ONE_HUNDRED_EIGHTY_DAYS: {
      days = 180;
      break;
    }
    case LookBack.SIX_MONTHS: {
      const today = startOfDay(new Date());
      days = differenceInDays(today, sub(today, { months: 6 }));
      break;
    }
    case LookBack.TWELVE_MONTHS: {
      const today = startOfDay(new Date());
      days = differenceInDays(today, sub(today, { months: 12 }));
      break;
    }
    case LookBack.TWENTY_FOUR_MONTHS: {
      const today = startOfDay(new Date());
      days = differenceInDays(today, sub(today, { months: 24 }));
      break;
    }
  }
  return days;
}

function safeCopyText<Key extends keyof typeof copyText>(
  key: Key
): (typeof copyText)[Key] {
  return copyText[key];
}

//
// CSV
//

function getCsvData(
  dimensions: Dimension[],
  measure: Measure,
  data: RawData[]
): CsvData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const csvAccessors = [
    ...dimensions.map((dimension) => dimension.schemaName),
    measure.schemaName,
    "estimatedTotalSpend",
    "estimatedDeltaSpend",
  ];

  const rows = data.map((datum) => {
    const totalForecastedSpend =
      Number(datum[measure.schemaName] ?? 0) +
      Number(datum[`${measure.schemaName}${FORECASTED_KEY}`] ?? 0);

    return {
      ...dimensions.reduce((accum, dimension) => {
        return {
          ...accum,
          [dimension.schemaName]: datum[dimension.schemaName],
        };
      }, {}),
      [measure.schemaName]: datum[measure.schemaName],
      estimatedTotalSpend: totalForecastedSpend,
      estimatedDeltaSpend:
        totalForecastedSpend -
        Number(datum[`${measure.schemaName}${COMPARISON_KEY}`] ?? 0),
    };
  });

  const headers = csvAccessors.map((csvAccessor) => ({
    key: csvAccessor,
    label: csvAccessor,
  }));

  return { headers, rows };
}

export default ForecastingViewContainer;
