import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  awsComputeChartOptionLabel_DISK: "Disk",
  awsComputeChartOptionLabel_FAMILY: "Family",
  awsComputeChartOptionLabel_MEMORY: "Memory",
  awsComputeChartOptionLabel_NETWORK: "Network",
  awsComputeChartOptionLabel_REGION: "Region",
  awsComputeChartOptionLabel_INSTANCE_TYPE: "Instance Type",
  awsComputeChartOptionLabel_USAGE_ACT: "Usage Act.",
  awsComputeChartOptionLabel_VCPU: "vCpu",
  awsComputeCostReportSnapshotName: "Elastic Compute Cloud Cost Chart",
  awsComputeMeterCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  awsComputeMeterDBStorageAllocated: "Allocated",
  awsComputeMeterDBStorageUsageLabel: "DB Storage Usage",
  awsComputeMeterDBStorageUsed: "Used",
  awsComputeMeterForecastedSpendComparisonCaption: "compared to last month",
  awsComputeMeterForecastedSpendLabel: "Forecasted Spend",
  awsComputeMeterLastMonthTotalCostLabel: "Last Month Total Spend",
  awsComputeMeterMTDCaption: "Change from this time last month",
  awsComputeMeterMTDSpendComparisonCaption: "vs. this time last month",
  awsComputeMeterRecommendationsLabel: "Total Current Recommendations",
  awsComputeOptimizationsChartTitleCost: "Aggregate Costs",
  awsComputeOptimizationsChartTitleUsage: "Usage",
  awsComputeOptimizationsChartUtilization: "Utilization",
  awsComputeTableInstanceGroupHeader_diskReadBytes: "Disk Read",
  awsComputeTableInstanceGroupHeader_diskWriteBytes: "Disk Write",
  awsComputeTableInstanceGroupHeader_family: "Family",
  awsComputeTableInstanceGroupHeader_familyType: "Type",
  awsComputeTableInstanceGroupHeader_instanceCount: "Instance Count",
  awsComputeTableInstanceGroupHeader_instanceUnits: "Instance Units",
  awsComputeTableInstanceGroupHeader_lineItemUsageAccountId: "Account",
  awsComputeTableInstanceGroupHeader_networkInBytes: "Network In",
  awsComputeTableInstanceGroupHeader_networkOutBytes: "Network Out",
  awsComputeTableInstanceGroupHeader_operatingSystem: "OS",
  awsComputeTableInstanceGroupHeader_productMemoryBytes: "Provisioned RAM",
  awsComputeTableInstanceGroupHeader_productVCPU: "Provisioned vCPU",
  awsComputeTableInstanceGroupHeader_region: "Region",
  awsComputeTableInstanceGroupHeader_totalCost: "Cost",
  awsComputeTableInstanceHeader_cpuUtilization: "vCPU Util",
  awsComputeTableInstanceHeader_diskReadBytes: "Disk Read",
  awsComputeTableInstanceHeader_diskWriteBytes: "Disk Write",
  awsComputeTableInstanceHeader_instanceId: "Resource Name",
  awsComputeTableInstanceHeader_instanceType: "Instance",
  awsComputeTableInstanceHeader_instanceUnits: "Instance Units",
  awsComputeTableInstanceHeader_networkInBytes: "Network In",
  awsComputeTableInstanceHeader_networkOutBytes: "Network Out",
  awsComputeTableInstanceHeader_productMemoryBytes: "Provisioned RAM",
  awsComputeTableInstanceHeader_productVCPU: "vCPU",
  awsComputeTableInstanceHeader_totalCost: "Cost",
  awsComputeTableInstanceGroupViewInstancesTooltip: "View Instances",
  awsComputeTableNull: "null",
  awsComputeTabOptimizations: "Optimizations",
  awsComputeTabVisibility: "Visibility",
  awsComputeUsageReportSnapshotName: "Elastic Compute Cloud Usage Chart",
  awsDatabaseCostAggregateChartTitle: "Aggregate Costs",
  awsDatabaseCostChartOptionLabel_account_id: "Account ID",
  awsDatabaseCostChartOptionLabel_cluster_id: "Cluster ID",
  awsDatabaseCostChartOptionLabel_databaseType: "Database Type",
  awsDatabaseCostChartOptionLabel_family: "Family",
  awsDatabaseCostChartOptionLabel_instance: "Instance",
  awsDatabaseCostChartOptionLabel_linkedAccount: "Linked Account",
  awsDatabaseCostChartOptionLabel_productFamily:"Product Family",
  awsDatabaseCostChartOptionLabel_region: "Region",
  awsDatabaseCostChartOptionLabel_storageType: "Storage Type",
  awsDatabaseElastiCacheChartOption_BACKUP_STORAGE: "Backup Storage",
  awsDatabaseElastiCacheChartOption_CACHE_ENGINE: "Cache Engine",
  awsDatabaseElastiCacheChartOption_CLUSTER_ID: "Cluster ID",
  awsDatabaseElastiCacheChartOption_INSTANCE_TYPE: "Instance Type",
  awsDatabaseElastiCacheChartOption_NODE_USAGE: "Node Usage",
  awsDatabaseElastiCacheChartOption_REGION: "Region",
  awsDatabaseElastiCacheChartOption_USAGE_ACCOUNT_ID: "Usage Account ID",
  awsDatabaseElastiCacheChartOption_USAGE_TYPE: "Usage Type",
  awsDatabaseElastiCacheCSVDownloadInstance: "Instance Data",
  awsDatabaseElastiCacheCSVDownloadInstanceGroup: "Table Data",
  awsDatabaseElastiCacheTableHeader_backupStorageCost: "Backup Storage Cost",
  awsDatabaseElastiCacheTableHeader_backupStorageUsage: "Backup Storage Usage",
  awsDatabaseElastiCacheTableHeader_cacheEngine: "Cache Engine",
  awsDatabaseElastiCacheTableHeader_clusterArn: "Cluster Arn",
  awsDatabaseElastiCacheTableHeader_clusterId: "Cluster Id",
  awsDatabaseElastiCacheTableHeader_cost: "Cost",
  awsDatabaseElastiCacheTableHeader_instanceFamily: "Instance Family",
  awsDatabaseElastiCacheTableHeader_instanceMemory: "Instance Memory",
  awsDatabaseElastiCacheTableHeader_instanceNetworkPerformance: "Instance Network Performance",
  awsDatabaseElastiCacheTableHeader_instanceType: "Instance Type",
  awsDatabaseElastiCacheTableHeader_instanceVcpu: "Instance Vcpu",
  awsDatabaseElastiCacheTableHeader_nodeCost: "Node Cost",
  awsDatabaseElastiCacheTableHeader_nodeUsage: "Node Usage",
  awsDatabaseElastiCacheTableHeader_nodeUsageHours: "Node Usage (Node Hours)",
  awsDatabaseElastiCacheTableHeader_payerAccountId: "Payer Account Id",
  awsDatabaseElastiCacheTableHeader_region: "Region",
  awsDatabaseElastiCacheTableHeader_usageAccountId: "Usage Account",
  awsDatabaseElastiCacheTableNull: "null",
  awsDatabaseElastiCacheTabVisibility: "Visibility",
  awsDatabaseElastiCacheTitle: "Amazon ElastiCache",
  awsDatabaseMemoryDBChartOptionLabel_BACKUP_STORAGE_USAGE: 'Backup Storage',
  awsDatabaseMemoryDBChartOptionLabel_CLUSTER_ID: 'Cluster ID',
  awsDatabaseMemoryDBChartOptionLabel_DATA_WRITE_USAGE: 'Data Write',
  awsDatabaseMemoryDBChartOptionLabel_INSTANCE_TYPE: 'Instance Type',
  awsDatabaseMemoryDBChartOptionLabel_NODE_USAGE: 'Node',
  awsDatabaseMemoryDBChartOptionLabel_REGION: 'Region',
  awsDatabaseMemoryDBChartOptionLabel_USAGE_ACCOUNT_ID: 'Usage Account ID',
  awsDatabaseMemoryDBChartOptionLabel_USAGE_TYPE: "Usage Type",
  awsDatabaseMemoryDBReportSnapshotNameCost: "Amazon MemoryDB Cost Chart",
  awsDatabaseMemoryDBReportSnapshotNameUsage: "Amazon MemoryDB Usage Chart",
  awsDatabaseMemoryDBTableHeader_clusterId: 'Cluster ID',
  awsDatabaseMemoryDBTableHeader_dataWriteCost: 'Data Write Cost',
  awsDatabaseMemoryDBTableHeader_instanceFamily: 'Instance Family',
  awsDatabaseMemoryDBTableHeader_instanceMemory: 'Instance Memory',
  awsDatabaseMemoryDBTableHeader_instanceNetworkPerformance: 'Network Performance',
  awsDatabaseMemoryDBTableHeader_instanceType: 'Instance Type',
  awsDatabaseMemoryDBTableHeader_instanceVcpu: 'Instance VCPU',
  awsDatabaseMemoryDBTableHeader_nodeCost: 'Node Cost',
  awsDatabaseMemoryDBTableHeader_payerAccountId: 'Payer Account ID',
  awsDatabaseMemoryDBTableHeader_region: 'Region',
  awsDatabaseMemoryDBTableHeader_usageAccountId: 'Usage Acct.',
  awsDatabaseMemoryDBTitle: "MemoryDB",
  awsDatabaseMetersCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  awsDatabaseMetersDBMemoryAllocated: "Allocated",
  awsDatabaseMetersDBMemoryUsageLabel: "Monthly DB Memory Usage To Date",
  awsDatabaseMetersDBMemoryUsed: "Used",
  awsDatabaseMetersForecastedSpendComparisonCaption: "compared to last month",
  awsDatabaseMetersForecastedSpendLabel: "Forecasted Spend",
  awsDatabaseMetersLastMonthTotalCostLabel: "Last Month Total Spend",  
  awsDatabaseMetersMTDSpendComparisonCaption: "vs. this time last month",
  awsDatabaseOptimizationsTabLabel: "Optimizations",
  awsDatabaseTableInstanceGroupHeader_databaseType:"Database Type",
  awsDatabaseTableInstanceGroupHeader_family:"Family",
  awsDatabaseTableInstanceGroupHeader_familyType:"Type",
  awsDatabaseTableInstanceGroupHeader_instanceCount:"Instance Count",
  awsDatabaseTableInstanceGroupHeader_instanceUnits:"Instance Units",
  awsDatabaseTableInstanceGroupHeader_lineItemUsageAccountId:"Account",
  awsDatabaseTableInstanceGroupHeader_networkBytes:"Network",
  awsDatabaseTableInstanceGroupHeader_operatingSystem:"OS",
  awsDatabaseTableInstanceGroupHeader_productMemoryBytes:"Provisioned RAM",
  awsDatabaseTableInstanceGroupHeader_productVCPU:"Provisioned vCPU",
  awsDatabaseTableInstanceGroupHeader_region:"Region",
  awsDatabaseTableInstanceGroupHeader_totalCost:"Cost",
  awsDatabaseTableInstanceGroupHeader_usedDiskBytes:"Used Disk",
  awsDatabaseTableInstanceGroupTooltipLabelViewInstances: "View Instances",
  awsDatabaseTableInstanceHeader_cpuUtilization: "vCPU Utilization",
  awsDatabaseTableInstanceHeader_databaseConnections: "Connections",
  awsDatabaseTableInstanceHeader_instanceId: "Resource ID",
  awsDatabaseTableInstanceHeader_instanceType: "Instance",
  awsDatabaseTableInstanceHeader_networkBytes: "Network Bytes",
  awsDatabaseTableInstanceHeader_productMemoryBytes: "Provisioned RAM",
  awsDatabaseTableInstanceHeader_productVCPU: "vCPU",
  awsDatabaseTableInstanceHeader_ramUtilization: "RAM Utilization",
  awsDatabaseTableInstanceHeader_totalCost: "Cost",
  awsDatabaseTableInstanceHeader_usedDiskBytes: "Used Disk",
  awsDatabaseTableInstanceTitle: "Instances",
  awsDatabaseTableNull: "null",
  awsDatabaseUsageChartOptionLabel_Connections: "Connections",
  awsDatabaseUsageChartOptionLabel_cpu: "CPU",
  awsDatabaseUsageChartOptionLabel_disk: "Disk",
  awsDatabaseUsageChartOptionLabel_iops: "I/O",
  awsDatabaseUsageChartOptionLabel_memory: "Memory",
  awsDatabaseUsageChartOptionLabel_network: "Network",
  awsDatabaseUsageChartTitle: "Usage",
  awsIAMListPermissionTooltipMessage: "IAM listAccountNames permissions required to see account name",
  awsKubernetesAccountsAllLabel: "All Accounts",
  awsKubernetesAccountsLoadingLabel: "Loading...",
  awsKubernetesChartLabelCost: "Cost",  
  awsKubernetesChartLabelUsage: "Usage",
  awsKubernetesCloudTitle: "Amazon Elastic Kubernetes Service (EKS)",
  awsKubernetesCostReportSnapshotName: "Elastic Kubernetes Service Cost Chart",
  awsKubernetesInstanceGroupHeader_accountID: "Account",
  awsKubernetesInstanceGroupHeader_coveredCost: "Covered Cost",
  awsKubernetesInstanceGroupHeader_cpuTotal: "Total Cores",
  awsKubernetesInstanceGroupHeader_cpuUsed: "CPU Utilization",
  awsKubernetesInstanceGroupHeader_memoryTotal: "Total Bytes",
  awsKubernetesInstanceGroupHeader_memoryUsed: "Memory Utilization",
  awsKubernetesInstanceGroupHeader_name: "Name",
  awsKubernetesInstanceGroupHeader_onDemandCost: "On Demand Cost",
  awsKubernetesInstanceGroupHeader_totalCost: "Total Cost",
  awsKubernetesInstanceGroupHeaderTooltip: "View Instances",
  awsKubernetesInstanceHeader_instanceId: "Instance ID",
  awsKubernetesInstanceHeader_nodeName: "Node Name",
  awsKubernetesInstanceTableModalHeader: "Instances",
  awsKubernetesMetersLabelBytes: "Used / Total Bytes",
  awsKubernetesMetersLabelCores: "Used / Total Cores",
  awsKubernetesMetersLabelTotalCost: "Total Cost",
  awsKubernetesTableNull: "null",
  awsKubernetesTabOptimizations: "Optimizations",
  awsKubernetesTabVisibility: "Visibility",
  awsKubernetesUsageOptionLabelCPU: "CPU",
  awsKubernetesUsageOptionLabelMemory: "Memory",
  awsKubernetesUsageReportSnapshotName: "Elastic Kubernetes Service Usage Chart",
  awsMemoryDBTitle: "Amazon MemoryDB",
  awsNodeHoursNumber: "%NUMBER% node-hrs",
  awsOpenSearchChartOptionLabel_DATA_TRANSFER_DIRECTION: "Data Transfer Direction",
  awsOpenSearchChartOptionLabel_DATA_TRANSFER: "Data Transfer",
  awsOpenSearchChartOptionLabel_DOMAIN_ID: "Domain ID",
  awsOpenSearchChartOptionLabel_INSTANCE_TYPE: "Instance Type",
  awsOpenSearchChartOptionLabel_REGION: "Region",
  awsOpenSearchChartOptionLabel_USAGE_ACCOUNT_ID: "Usage Account ID",
  awsOpenSearchChartOptionLabel_USAGE_TYPE: "Usage Type",
  awsOpenSearchCostReportSnapshotName: "AWS OpenSearch Cost Report",
  awsOpenSearchInstanceGroupTableButtonTooltip: "View Instance",
  awsOpenSearchInstanceGroupTableHeader_awsInbound: "AWS Inbound",
  awsOpenSearchInstanceGroupTableHeader_awsOutbound: "AWS Outbound",
  awsOpenSearchInstanceGroupTableHeader_blockStorageCost: "Block Storage Cost",
  awsOpenSearchInstanceGroupTableHeader_dataTransferCost: "Data Transfer Cost",
  awsOpenSearchInstanceGroupTableHeader_domainId: "Domain ID",
  awsOpenSearchInstanceGroupTableHeader_instanceCost: "Instance Cost",
  awsOpenSearchInstanceGroupTableHeader_interRegionInbound: "Inter Region Inbound",
  awsOpenSearchInstanceGroupTableHeader_interRegionOutbound: "Inter Region Outbound",
  awsOpenSearchInstanceGroupTableHeader_region: "Region",
  awsOpenSearchInstanceGroupTableHeader_usageAccountId: "Usage Account",
  awsOpenSearchInstanceModalSubtitle: "Account ID: %ACCOUNT%",
  awsOpenSearchInstanceModalTitle: "Instance for %DOMAIN%",
  awsOpenSearchInstanceTableHeader_instanceCost: "Instance Cost",
  awsOpenSearchInstanceTableHeader_instanceMemory: "Instance Memory",
  awsOpenSearchInstanceTableHeader_instanceType: "Instance Type",
  awsOpenSearchInstanceTableHeader_instanceVcpu: "vCPU",
  awsOpenSearchInstanceTableHeader_nodeUsage: "Node Usage",
  awsOpenSearchUsageReportSnapshotName: "AWS OpenSearch Usage Report",
  awsQarehouseUsageChartOptionLabel_backup_storage: "Backup Storage",
  awsQarehouseUsageChartOptionLabel_data_scan: "Data Scan",
  awsQarehouseUsageChartOptionLabel_node: "Node",
  awsQarehouseUsageChartOptionLabel_serverless_compute: "Serverless Compute",
  awsQarehouseUsageChartOptionLabel_storage: "Storage",
  awsRedshiftCSVExportTitle: "aws-redshift-warehouse-usage",
  awsRedshiftMetersLabelForecast:"Forecast Spend",
  awsRedshiftWarehouseAggregateCostChartTitle: "Cost",
  awsRedshiftWarehouseChartNoData: "No Cost Data to Display",
  awsServicesListTitle: "AWS",
  awsServicesListTitleNotConfigured: "AWS (Not Configured)",
  awsWarehouseCostChartOptionLabel_instance_type: "Instance Type",
  awsWarehouseCostChartOptionLabel_region: "Region",
  awsWarehouseCostChartOptionLabel_usage_type: "Usage Type",
  awsWarehouseResourceGroupHeader_backupStorageCost: "backupStorageCost",
  awsWarehouseResourceGroupHeader_clusterId: "Cluster ID",
  awsWarehouseResourceGroupHeader_cost: "Total $",
  awsWarehouseResourceGroupHeader_dataScanCost:"dataScanCost",
  awsWarehouseResourceGroupHeader_instanceFamily:"instanceFamily",
  awsWarehouseResourceGroupHeader_instanceMemory:"instanceMemory",
  awsWarehouseResourceGroupHeader_instanceType:"instanceType",
  awsWarehouseResourceGroupHeader_instanceVcpu:"instanceVcpu",
  awsWarehouseResourceGroupHeader_io:"io",
  awsWarehouseResourceGroupHeader_nodeCost:"nodeCost",
  awsWarehouseResourceGroupHeader_region:"region",
  awsWarehouseResourceGroupHeader_serverlessComputeCost:"serverlessComputeCost",
  awsWarehouseResourceGroupHeader_storage:"storage",
  awsWarehouseResourceGroupHeader_storageCost:"storageCost",
  awsWarehouseResourceGroupHeader_usageAccountId:"usageAccountId",
  awsWarehouseResourcesTableModalHeader: "Resources",
  awsWarehouseResourceTableGroupHeader_backupStorageCost: "backupStorageCost",
  awsWarehouseResourceTableGroupHeader_clusterId: "Cluster ID",
  awsWarehouseResourceTableGroupHeader_cost: "Total $",
  awsWarehouseResourceTableGroupHeader_dataScanCost:"Data Scan $",
  awsWarehouseResourceTableGroupHeader_instanceFamily:"instanceFamily",
  awsWarehouseResourceTableGroupHeader_instanceMemory:"instanceMemory",
  awsWarehouseResourceTableGroupHeader_instanceType:"instanceType",
  awsWarehouseResourceTableGroupHeader_instanceVcpu:"instanceVcpu",
  awsWarehouseResourceTableGroupHeader_io:"io",
  awsWarehouseResourceTableGroupHeader_nodeCost:"Node $",
  awsWarehouseResourceTableGroupHeader_region:"Region",
  awsWarehouseResourceTableGroupHeader_serverlessComputeCost:"serverlessComputeCost",
  awsWarehouseResourceTableGroupHeader_storage:"storage",
  awsWarehouseResourceTableGroupHeader_storageCost:"Storage $",
  awsWarehouseResourceTableGroupHeader_usageAccountId:"Usage Acct.",
  awsWarehouseResourceTableHeader_backupStorageCost:"backupStorageCost",
  awsWarehouseResourceTableHeader_backupStorageUsage:"backupStorageUsage",
  awsWarehouseResourceTableHeader_clusterArn:"clusterArn",
  awsWarehouseResourceTableHeader_clusterId:"clusterId",
  awsWarehouseResourceTableHeader_cost:"cost",
  awsWarehouseResourceTableHeader_dataScanCost:"dataScanCost",
  awsWarehouseResourceTableHeader_dataScanUsage:"dataScanUsage",
  awsWarehouseResourceTableHeader_instanceMemory:"instanceMemory",
  awsWarehouseResourceTableHeader_instanceType:"instanceType",
  awsWarehouseResourceTableHeader_instanceVcpu:"instanceVcpu",
  awsWarehouseResourceTableHeader_io:"io",
  awsWarehouseResourceTableHeader_nodeCost:"nodeCost",
  awsWarehouseResourceTableHeader_nodeUsage:"nodeUsage",
  awsWarehouseResourceTableHeader_payerAccountId:"payerAccountId",
  awsWarehouseResourceTableHeader_productFamily:"productFamily",
  awsWarehouseResourceTableHeader_region:"region",
  awsWarehouseResourceTableHeader_serverlessComputeCost:"serverlessComputeCost",
  awsWarehouseResourceTableHeader_serverlessComputeUsage:"serverlessComputeUsage",
  awsWarehouseResourceTableHeader_storage:"storage",
  awsWarehouseResourceTableHeader_storageCost:"storageCost",
  awsWarehouseResourceTableHeader_storageUsage:"storageUsage",
  awsWarehouseResourceTableHeader_usageAccountId:"usageAccountId",
  awsWarehouseTableNull: "null",
  awsWarehouseTableViewResource: "View Resource",
  azureCategoryFilterLabel_blockStorage: "Block Storage",
  azureCategoryFilterLabel_instances: "Instances",
  azureComputeInstancesTableTitle: "Instances - %PROJECT_ID%",
  azureComputeTableHeader_blockStorageClass: "blockStorageClass",
  azureComputeTableHeader_blockStorageType: "blockStorageType",
  azureComputeTableHeader_cost: "Cost $",
  azureComputeTableHeader_cpuUtilization: "CPU Util",
  azureComputeTableHeader_cpuUtilizationAvg: "Avg CPU Util %",
  azureComputeTableHeader_cpuUtilizationMax: "Max CPU Util %",
  azureComputeTableHeader_cpuUtilizationMin: "Min CPU Util %",
  azureComputeTableHeader_diskReadBytes: "Disk Read",
  azureComputeTableHeader_diskWriteBytes: "Disk Write",
  azureComputeTableHeader_family: "Family",
  azureComputeTableHeader_instanceCount: "Instance Count",
  azureComputeTableHeader_instanceId: "Instance ID",
  azureComputeTableHeader_instanceName: "Instance Name",
  azureComputeTableHeader_instanceType: "Type",
  azureComputeTableHeader_location: "Location",
  azureComputeTableHeader_operatingSystem: "OS",
  azureComputeTableHeader_productMemoryBytes: "Product Memory",
  azureComputeTableHeader_productVCPU: "Product vCPU",
  azureComputeTableHeader_projectId: "Project ID",
  azureComputeTableHeader_ramAvailableBytes: "Memory Available",
  azureComputeTableHeader_ramAvailableBytesAvg: "Avg Memory Available",
  azureComputeTableHeader_ramAvailableBytesMax: "Max Memory Available",
  azureComputeTableHeader_ramAvailableBytesMin: "Min Memory Available",
  azureComputeTableHeader_receivedBytes: "Network In",
  azureComputeTableHeader_sentBytes: "Network Out",
  azureComputeTableHeader_usageAmount: "Usage Amount",
  azureComputeTableNull: "null",
  azureComputeTableViewInstances: "View Instances",
  azureCSVDownloadCluster: "Cluster Data",
  azureCSVDownloadInstance: "Instance Data",
  azureCSVDownloadInstanceGroup: "Table Data",
  azureDatabaseCostAggregateChartTitle: "Aggregate Costs",
  azureDatabaseCostChartOptionLabel_billingMethod: "Billing Method",
  azureDatabaseCostChartOptionLabel_computeTier: "Compute Tier",
  azureDatabaseCostChartOptionLabel_databaseType: "Database Type",
  azureDatabaseCostChartOptionLabel_instance: "Instance",
  azureDatabaseCostChartOptionLabel_projectId: "Project ID",
  azureDatabaseCostChartOptionLabel_region: "Region",
  azureDatabaseCostChartOptionLabel_sku_teir:"SKU",
  azureDatabaseInstanceTableModalHeader: "Instances",
  azureDatabaseMetersCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  azureDatabaseMetersDBMemoryAllocated: "Allocated",
  azureDatabaseMetersDBMemoryUsageLabel: "Monthly DB Memory Usage To Date",
  azureDatabaseMetersDBMemoryUsed: "Used",
  azureDatabaseMetersForecastedSpendComparisonCaption: "compared to last month",
  azureDatabaseMetersForecastedSpendLabel: "Forecasted Spend",
  azureDatabaseMetersLastMonthTotalCostLabel: "Last Month Total Spend",  
  azureDatabaseMetersMTDSpendComparisonCaption: "vs. this time last month",
  azureDatabaseOptimizationsTabLabel: "Optimizations",
  azureDatabaseResourceUsageReadIOPS: "read I/O",
  azureDatabaseResourceUsageUsed: "used",
  azureDatabaseResourceUsageWriteIOPS: "write I/O",
  azureDatabasesLabel: "Azure SQL",
  azureDatabaseTableInstanceGroupHeader_absoluteCredits:"Credits",
  azureDatabaseTableInstanceGroupHeader_backupRetentionPeriodDays:"Backup Days",
  azureDatabaseTableInstanceGroupHeader_backupStorageRedundancy:"Storage Redundancy",
  azureDatabaseTableInstanceGroupHeader_billingMethod:"Billing",
  azureDatabaseTableInstanceGroupHeader_computeTier:"Compute Tier",
  azureDatabaseTableInstanceGroupHeader_connections:"Connections",
  azureDatabaseTableInstanceGroupHeader_cores:"CPU Total",
  azureDatabaseTableInstanceGroupHeader_coreUsage:"CPU Usage",
  azureDatabaseTableInstanceGroupHeader_cost:"Cost",
  azureDatabaseTableInstanceGroupHeader_cpuPercentAvg:"CPU Avg %",
  azureDatabaseTableInstanceGroupHeader_cpuPercentMax:"CPU Max %",
  azureDatabaseTableInstanceGroupHeader_cpuPercentMin:"CPU Min %",
  azureDatabaseTableInstanceGroupHeader_databaseEngine:"Database",
  azureDatabaseTableInstanceGroupHeader_highAvailabilityEnabled:"High Availability",
  azureDatabaseTableInstanceGroupHeader_instanceName:"Name",
  azureDatabaseTableInstanceGroupHeader_location:"Region",
  azureDatabaseTableInstanceGroupHeader_memoryBytesUsage:"Memory Usage",
  azureDatabaseTableInstanceGroupHeader_memoryGB:"Memory Total",
  azureDatabaseTableInstanceGroupHeader_memoryPercentAvg:"Memory Avg %",
  azureDatabaseTableInstanceGroupHeader_memoryPercentMax:"Memory Max %",
  azureDatabaseTableInstanceGroupHeader_memoryPercentMin:"Memory Min %",
  azureDatabaseTableInstanceGroupHeader_projectId:"Project Id",
  azureDatabaseTableInstanceGroupHeader_resourceCount:"Count",
  azureDatabaseTableInstanceGroupHeader_storageCapacityGB:"Disk Total",
  azureDatabaseTableInstanceGroupHeader_storagePercent:"Disk %",
  azureDatabaseTableInstanceGroupHeader_usageAmount:"Usage",
  azureDatabaseTableInstanceGroupTooltipLabelViewInstances: "View Instances",
  azureDatabaseTableInstanceHeader_absoluteCredits:"Credits",
  azureDatabaseTableInstanceHeader_backupRetentionPeriodDays:"Backup Days",
  azureDatabaseTableInstanceHeader_backupStorageRedundancy:"Backup Storage",
  azureDatabaseTableInstanceHeader_billingMethod:"Billing",
  azureDatabaseTableInstanceHeader_computeTier:"Compute Tier",
  azureDatabaseTableInstanceHeader_connections:"Connections",
  azureDatabaseTableInstanceHeader_cores:"CPU Total",
  azureDatabaseTableInstanceHeader_coreUsage:"CPU Usage",
  azureDatabaseTableInstanceHeader_cost:"Cost",
  azureDatabaseTableInstanceHeader_cpuPercentAvg:"CPU Avg %",
  azureDatabaseTableInstanceHeader_cpuPercentMax:"CPU Max %",
  azureDatabaseTableInstanceHeader_cpuPercentMin:"CPU Min %",
  azureDatabaseTableInstanceHeader_databaseEngine:"Database",
  azureDatabaseTableInstanceHeader_highAvailabilityEnabled:"High Availability",
  azureDatabaseTableInstanceHeader_instanceName:"Name",
  azureDatabaseTableInstanceHeader_location:"Region",
  azureDatabaseTableInstanceHeader_memoryBytesUsage:"Memory Usage",
  azureDatabaseTableInstanceHeader_memoryGB:"Memory Total",
  azureDatabaseTableInstanceHeader_memoryPercentAvg:"Memory Avg %",
  azureDatabaseTableInstanceHeader_memoryPercentMax:"Memory Max %",
  azureDatabaseTableInstanceHeader_memoryPercentMin:"Memory Min %",
  azureDatabaseTableInstanceHeader_projectId:"Project Id",
  azureDatabaseTableInstanceHeader_skuName:"Sku Name",
  azureDatabaseTableInstanceHeader_skuTier:"Sku Tier",
  azureDatabaseTableInstanceHeader_storageCapacityGB:"Disk Total",
  azureDatabaseTableInstanceHeader_storagePercent:"Disk Percent",
  azureDatabaseTableInstanceHeader_usageAmount:"Usage",
  azureDatabaseTableInstanceTitle: "Instances",
  azureDatabaseTableNull: "null",
  azureDatabaseUsageChartOptionLabel_connections: "Connections",
  azureDatabaseUsageChartOptionLabel_cpu: "CPU",
  azureDatabaseUsageChartOptionLabel_disk: "Disk",
  azureDatabaseUsageChartOptionLabel_iops: "I/O",
  azureDatabaseUsageChartOptionLabel_memory: "Memory",
  azureDatabaseUsageChartOptionLabel_network: "Network",
  azureDatabaseUsageChartTitle: "Usage",
  azureKubernetesAccountsAllLabel: "All Accounts",
  azureKubernetesAccountsLoadingLabel: "Loading...",
  azureKubernetesChartLabelCost: "Cost",  
  azureKubernetesChartLabelUsage: "Usage",
  azureKubernetesCloudTitle: "Azure Kubernetes Service (AKS)",
  azureKubernetesClustersTableModalHeader: "Clusters",
  azureKubernetesCostReportSnapshotName: "Azure Kubernetes Service Cost Chart",
  azureKubernetesInstanceGroupHeader_cloudId: "Cloud ID",
  azureKubernetesInstanceGroupHeader_clusterName: "Cluster Name",
  azureKubernetesInstanceGroupHeader_cost: "Total Cost",
  azureKubernetesInstanceGroupHeader_estimatedCpuCoreHoursAvailable: "CPU Available",
  azureKubernetesInstanceGroupHeader_estimatedRssByteHoursAvailable: "Memory Available",
  azureKubernetesInstanceGroupHeader_instanceHours: "Instance Hours",
  azureKubernetesInstanceGroupHeader_instanceType: "Instance Type",
  azureKubernetesInstanceGroupHeader_nodepool: "Nodepool",
  azureKubernetesInstanceGroupHeader_resourceGroupName: "Resource",
  azureKubernetesInstanceGroupHeader_subscriptionName: "Subscription",
  azureKubernetesInstanceGroupHeader_upperBoundCpuCoreHoursUsed: "CPU Used",
  azureKubernetesInstanceGroupHeader_upperBoundRssByteHoursUsed: "Memory Used",
  azureKubernetesTableHeader_cloudId: "ID",
  azureKubernetesTableHeader_clusterName: "Name",
  azureKubernetesTableHeader_cost: "Cost $",
  azureKubernetesTableHeader_estimatedCpuCoreHoursAvailable: "CPU Total",
  azureKubernetesTableHeader_estimatedRssByteHoursAvailable: "Memory Total",
  azureKubernetesTableHeader_instanceHours: "Hours",
  azureKubernetesTableHeader_resourceGroupName: "Resource",
  azureKubernetesTableHeader_subscriptionName: "Subscription",
  azureKubernetesTableHeader_upperBoundCpuCoreHoursUsed: "CPU Used",
  azureKubernetesTableHeader_upperBoundRssByteHoursUsed: "Memory Used",
  azureKubernetesTableInstanceHeader_cloudId: "ID",
  azureKubernetesTableInstanceHeader_clusterName: "Name",
  azureKubernetesTableInstanceHeader_cost: "Cost $",
  azureKubernetesTableInstanceHeader_estimatedCpuCoreHoursAvailable: "CPU Total",
  azureKubernetesTableInstanceHeader_estimatedRssByteHoursAvailable: "Memory Total",
  azureKubernetesTableInstanceHeader_instanceHours: "Hours",
  azureKubernetesTableInstanceHeader_instanceType: "Instance",
  azureKubernetesTableInstanceHeader_nodepool: "Nodepool",
  azureKubernetesTableInstanceHeader_resourceGroupName: "Resource",
  azureKubernetesTableInstanceHeader_subscriptionName: "Subscription",
  azureKubernetesTableInstanceHeader_totalCost: "Cost",
  azureKubernetesTableInstanceHeader_upperBoundCpuCoreHoursUsed: "CPU Used",
  azureKubernetesTableInstanceHeader_upperBoundRssByteHoursUsed: "Memory Used",
  azureKubernetesTableNull: "null",
  azureKubernetesTableViewClusters: "View Clusters",
  azureKubernetesUsageOptionLabelCPU: "CPU",
  azureKubernetesUsageOptionLabelMemory: "Memory",
  azureKubernetesUsageReportSnapshotName: "Azure Kubernetes Service Usage Chart",
  azureServicesListTitle: "Azure",
  azureServicesListTitleNotConfigured: "Azure (Not Configured)",
  azureStorageBBChartOptionLabel_ACCOUNT_ID: "Account ID",
  azureStorageBBChartOptionLabel_BLOB_SIZE: "Blob Size",
  azureStorageBBChartOptionLabel_NETWORK: "Network",
  azureStorageBBChartOptionLabel_PRICING_MODEL: "Pricing Model",
  azureStorageBBChartOptionLabel_PROJECT_ID: "Project ID",
  azureStorageBBChartOptionLabel_RESOURCE_ID: "Resource ID",
  azureStorageBBChartOptionLabel_STORAGE_ACCOUNT: "Storage Account",
  azureStorageBBChartOptionLabel_TRANSACTIONS: "Transactions",
  azureStorageBBChartOptionLabel_USAGE_AMOUNT: "Usage Amount",
  azureStorageBBInstancesTableTitle: "Storage Account - %STORAGE_ACCOUNT%",
  azureStorageBBReportSnapshotNameCost: "Azure Blob Storage Cost Chart",
  azureStorageBBReportSnapshotNameUsage: "Azure Blob Storage Usage Chart",
  azureStorageBBTableHeader_absoluteCredits: "Absolute Credits",
  azureStorageBBTableHeader_blobSizeBytes: "Used Storage",
  azureStorageBBTableHeader_bytesDownloaded: "Bytes Downloaded",
  azureStorageBBTableHeader_bytesUploaded: "Bytes Uploaded",
  azureStorageBBTableHeader_containerCount: "Container Count",
  azureStorageBBTableHeader_cost: "Cost",
  azureStorageBBTableHeader_pricingModel: "Pricing Model",
  azureStorageBBTableHeader_projectId: "Project ID",
  azureStorageBBTableHeader_resourceId: "Resource ID",
  azureStorageBBTableHeader_skuDescription: "Sku Description",
  azureStorageBBTableHeader_storageAccount: "Storage Account",
  azureStorageBBTableHeader_transactions: "Transactions",
  azureStorageBBTableHeader_usageAmount: "Usage Amount",
  azureStorageBBTabOptimizations: "Optimizations",
  azureStorageBBTabVisibility: "Visibility",
  azureStorageBBTitle: "Azure Blob Storage",
  azureVMComputeChartOptionLabel_CATEGORY: "Category",
  azureVMComputeChartOptionLabel_DISK: "Disk",
  azureVMComputeChartOptionLabel_FAMILY: "Family",
  azureVMComputeChartOptionLabel_INSTANCE_NAME: "Instance Name",
  azureVMComputeChartOptionLabel_LOCATION: "Location",
  azureVMComputeChartOptionLabel_MEMORY: "Memory",
  azureVMComputeChartOptionLabel_NETWORK: "Network",
  azureVMComputeChartOptionLabel_PROJECT_ID: "Project ID",
  azureVMComputeChartOptionLabel_VCPU: "vCpu",
  azureVMComputeTabOptimizations: "Optimizations",
  azureVmComputeTabVisibility: "Visibility",
  azureVMsComputeCloudTitle: "Azure Virtual Machines (VMs)",
  bigQueryAccountFiltersLabel: "Account Filters",
  bigQueryCostAggregateChartTitle: "Aggregate Costs",
  bigQueryCostByLabelChartTitle: "On Demand Cost By Job Label",
  bigQueryCostByLabelReportSnapshotTitle: "On Demand Cost by BigQuery Job Label Chart",
  bigQueryCostChartOptionLabel_project: "Project",
  bigQueryCostChartOptionLabel_region: "Region",
  bigQueryCostChartOptionLabel_reservation: "Reservation",
  bigQueryCostChartOptionLabel_usageType: "Usage Type",
  bigQueryCostChartOptionLabel_user: "User",
  bigQueryCostReportSnapshotTitle:"BigQuery Cost Chart",
  bigQueryDatasetGroupTableHeader_datasetId: "Dataset",
  bigQueryDatasetGroupTableHeader_jobCount: "Job Count",
  bigQueryDatasetGroupTableHeader_maxJobSlots: "Max Slots",
  bigQueryDatasetGroupTableHeader_onDemandCost: "Cost",
  bigQueryDatasetGroupTableHeader_projectId: "Project",
  bigQueryDatasetGroupTableHeader_queryBytes: "Query Bytes",
  bigQueryDatasetGroupTableHeader_queryCount: "Query Count",
  bigQueryDatasetGroupTableNull: "null",
  bigQueryEditionTableHeader_adminProjectId: "Admin Project",
  bigQueryEditionTableHeader_edition: "Edition",
  bigQueryEditionTableHeader_jobCount: "Job Count",
  bigQueryEditionTableHeader_potentialSlotS: "Potential Slot Seconds",
  bigQueryEditionTableHeader_region: "Region",
  bigQueryEditionTableHeader_reservationName: "Reservation Name",
  bigQueryEditionTableHeader_usedSlotS: "Used Slot Seconds",
  bigQueryEditionTableHeader_utilization: "Utilization",
  bigQueryEditionTableModalHeaderAnd: "and",
  bigQueryEditionTableModalHeaderPrepositionDateRange: "for",
  bigQueryEditionTableModalHeaderPrepositionDuration: "over the",
  bigQueryEditionTypeEnterprise: "Enterprise",
  bigQueryEditionTypeEnterprisePlus: "Enterprise Plus",
  bigQueryEditionTypeStandard: "Standard",
  bigQueryLabelFiltersLabel: "Label Filters",
  bigQueryMeterRateOptimizationsCaption: "Est. monthly savings",
  bigQueryMeterRateOptimizationsTitle: "Available Rate Optimizations",
  bigQueryMeterSpendComparisonCaption: "vs. previous period",
  bigQueryMeterSpendComparisonLabel: "Spend across %RANGE%",
  bigQueryMeterUsageOptimizationsCaption: "Est. monthly savings",
  bigQueryMeterUsageOptimizationsTitle: "Available Usage Optimizations",
  bigQueryUsageChartOptionLabel_processing: "Processing",
  bigQueryUsageChartOptionLabel_storage: "Storage",
  bigQueryUsageChartTitle: "Usage",
  bigQueryUsageReportSnapshotTitle: "BigQuery Usage Chart",
  breakdownGroupByLabel: "Grouped By",
  carbonNeutralTooltipCaption: "carbon-neutral",
  carbonNonNeutralTooltipCaption: "non carbon-neutral",
  categoryCompute: "Compute",
  categoryKubernetes: "Kubernetes",
  categoryStorage: "Storage",
  chartEmptyPlaceholderText: "No Usage Data to Display",
  cloudRunCostAggregateChartTitle: "Aggregate Costs",
  cloudRunCostChartOptionLabel_category: "Category",
  cloudRunCostChartOptionLabel_projectAccount: "Project/Account",
  cloudRunCostChartOptionLabel_region: "Region",
  cloudRunTableNull: "null",
  cloudRunTableServiceGroupHeader_averageMemory: "Average Provisioned RAM",
  cloudRunTableServiceGroupHeader_averageVCPU: "Average Provisioned vCPU",
  cloudRunTableServiceGroupHeader_cost: "Cost",
  cloudRunTableServiceGroupHeader_lowCarbon: "Low Carbon",
  cloudRunTableServiceGroupHeader_projectId: "Project",
  cloudRunTableServiceGroupHeader_region: "Region",
  cloudRunTableServiceGroupHeader_serviceCount: "Service Count",
  cloudRunTableServiceGroupHeader_type: "Type", 
  cloudRunTableServiceGroupHighCarbonToolTip: "This region does not satisfy the constraints to be considered low-carbon",
  cloudRunTableServiceGroupLowCarbonToolTip: "Google reports this region as being low-carbon",
  cloudRunTableServiceGroupTooltipLabelViewServices: "View Services",
  cloudRunTableServiceHeader_cpuAllocationTime: "Provisioned vCPU Time",
  cloudRunTableServiceHeader_memoryAllocationTime: "Provisioned Memory Time",
  cloudRunTableServiceHeader_peakInstanceCount: "Peak Instance Count",
  cloudRunTableServiceHeader_requestCount: "Request Count",
  cloudRunTableServiceHeader_revisionName: "Revision Name",
  cloudRunTableServiceHeader_serviceName: "Service Name / ProjectID",
  cloudRunTableServicesTitle: "Services",
  cloudRunTitle: "Google Cloud Run",
  cloudRunUsageChartOptionLabel_memory: "Memory",
  cloudRunUsageChartOptionLabel_vCPU: "vCPU",
  cloudRunUsageChartTitle: "Usage",
  cloudSannerCostChartOption_projectID: "Project ID",
  cloudSpannerCostChartOption_instanceID: "Instance ID",
  cloudSpannerCostChartOption_region: "Region",
  cloudSpannerCostChartOption_usageType: "Usage Type",
  cloudSpannerCostChartTitle: "Aggregate Cost",
  cloudSpannerCostReportSnapshotTitle: "Cloud Spanner Cost Chart",
  cloudSpannerInstanceModalHeader: "Instance(s) for %PROJECT%",
  cloudSpannerInstanceTableHeaderBackup: "Backup (GB Max)",
  cloudSpannerInstanceTableHeaderCost: "Cost",
  cloudSpannerInstanceTableHeaderInstanceID: "Instance ID",
  cloudSpannerInstanceTableHeaderNetwork: "Network (GB)",
  cloudSpannerInstanceTableHeaderProvisionedUnits: "Provisioned Processing (Units)",
  cloudSpannerInstanceTableHeaderStorage: "Storage (GB Max)",
  cloudSpannerRegionKeyMulti: "MULTI_REGION",
  cloudSpannerRegionKeysRegion: "REGIONAL",
  cloudSpannerResourceTableHeaderBackupCost: "Backup Cost",
  cloudSpannerResourceTableHeaderComputeCost: "Compute Cost",
  cloudSpannerResourceTableHeaderEgressCost: "Egress Cost",
  cloudSpannerResourceTableHeaderProject: "Project",
  cloudSpannerResourceTableHeaderRegion: "Region",
  cloudSpannerResourceTableHeaderStorageCost: "Storage Cost",
  cloudSpannerResourceTableHeaderTotalCost: "Total Cost",
  cloudSpannerUsageChartOption_backup: "Backup",
  cloudSpannerUsageChartOption_computeMultiRegion: "Compute Multi Regional",
  cloudSpannerUsageChartOption_computeRegion: "Compute Regional",
  cloudSpannerUsageChartOption_dbStorage: "DB Storage",
  cloudSpannerUsageChartOption_networkEgress: "Network Egress",
  cloudSpannerUsageChartTitle: "Usage",
  cloudSpannerUsageReportSnapshotTitle: "Cloud Spanner Usage Chart",
  cloudSQLBreadcrubLabelAll: "All",
  cloudSQLBreadcrubLabelTitle: "Classification:",
  cloudSQLClassificationLabel: "Classification: ",
  cloudSQLClassificationLabelAll: "All",
  cloudSQLClassificationLabelProjectId: "Project ID: ",
  cloudSQLCostAggregateChartTitle: "Aggregate Costs",
  cloudSQLCostChartTitle: "Costs",
  cloudSQLCostEmptyPlaceholderText: "No Cost Data to Display",
  cloudSQLCostReportSnapshotName: "Cloud SQL Cost Chart",
  cloudSQLDimensionGroupingLabelAll: "All",
  cloudSQLDimensionGroupingLabelDatabaseType: "Database Type",
  cloudSQLDimensionGroupingLabelProjectID: "Project ID",
  cloudSQLDimensionGroupingLabelRegion: "Region",
  cloudSQLInstanceTableHeaderDatabaseId:"Instance ID",
  cloudSQLInstanceTableHeaderMaxCpuAverageUsedCores:"Max CPU Util.",
  cloudSQLInstanceTableHeaderMaxCpuReservedCores:"Provisioned CPU",
  cloudSQLInstanceTableHeaderMaxDiskSizeBytes:"Provisioned Disk",
  cloudSQLInstanceTableHeaderMaxDiskUsedBytes:"Max Disk Util.",
  cloudSQLInstanceTableHeaderMaxRamAverageUsedBytes:"Max RAM Util.",
  cloudSQLInstanceTableHeaderRamReservedBytes:"Prov. RAM",
  cloudSQLInstanceTableHeaderTotalCost:"Cost",
  cloudSQLMetersCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  cloudSQLMetersDBStorageAllocated: "Allocated",
  cloudSQLMetersDBStorageUsageLabel: "DB Storage Usage",
  cloudSQLMetersDBStorageUsed: "Used",
  cloudSQLMetersForecastedSpendComparisonCaption: "compared to last month",
  cloudSQLMetersForecastedSpendLabel: "Forecasted Spend",
  cloudSQLMetersLastMonthTotalCostLabel: "Last Month Total Spend",
  cloudSQLMetersMTDSpendComparisonCaption: "vs. this time last month",
  cloudSQLOptimizationsTabLabel: "Optimizations",
  cloudSQLOtherDBType: "Other",
  cloudSQLResourceGroupingLabelCPU: "CPU",
  cloudSQLResourceGroupingLabelDisk: "DISK",
  cloudSQLResourceGroupingLabelMemory: "MEMORY",
  cloudSQLResourceGroupingLabelNetwork: "NETWORK",
  cloudSQLResourceTableHeaderTotalBytes: "(Avg) Used Bytes",
  cloudSQLResourceTableHeaderTotalCores: "(Avg) Used Cores",
  cloudSQLResourceTableHeaderTotalProvisionedBytes: "Provisioned Bytes",
  cloudSQLResourceTableHeaderTotalProvisionedCores: "Provisioned Cores",
  cloudSQLResourceTableLabelNotAvailable: "N/A",
  cloudSQLResourceTableTooltipLabelViewInstances: "View Instances",
  cloudSQLResourceTableTooltipLabelViewNoInstances: "No Instances",
  cloudSQLTitle: "Google Cloud SQL",
  cloudSQLUsageChartTitle: "Usage",
  cloudSQLUsageReportSnapshotName: "Cloud SQL Usage Chart",
  cloudSQLUsageTypeLabelCPU: "CPU",
  cloudSQLUsageTypeLabelDisk: "Disk",
  cloudSQLUsageTypeLabelMemory: "Memory",
  cloudSQLUsageTypeLabelNetwork: "Network",
  cloudSQLVisibilityTabLabel: "Visibility",
  computeCostBreakdownTitle: "Cost Breakdown",
  computeCostGroupedByLabel: "Grouped By",
  computeEngineTitle: "Google Compute Engine",
  ebsChartOptionLabel_ACCOUNT_ID: "Payer Account ID",
  ebsChartOptionLabel_IOPS: "IOPS",
  ebsChartOptionLabel_READ_WRITE: "Read/Write",
  ebsChartOptionLabel_REGION: "Region",
  ebsChartOptionLabel_SNAPSHOT_STORAGE: "Snapshot Storage",
  ebsChartOptionLabel_STORAGE_MEDIA: "Storage Media",
  ebsChartOptionLabel_STORAGE: "Volume Storage",
  ebsChartOptionLabel_THROUGHPUT: "Throughput",
  ebsChartOptionLabel_USAGE_ACCOUNT: "Usage Account",
  ebsSubTableSnapshotGroupHeader_lineItemUsageAccountId:"Linked Account ID",
  ebsSubTableSnapshotGroupHeader_snapshotId:"Snapshot ID",
  ebsSubTableSnapshotGroupHeader_snapshotUsageBytes:"Snapshot Usage",
  ebsSubTableSnapshotGroupHeader_totalCost:"$ Total",
  ebsSubTableVolumeGroupHeader_lineItemUsageAccountId:"Linked Account ID",
  ebsSubTableVolumeGroupHeader_maxThroughputVolumeBytes:"Throughput Bytes",
  ebsSubTableVolumeGroupHeader_operationsCost:"$ Operations",
  ebsSubTableVolumeGroupHeader_snapshotCost:"$ Snapshot",
  ebsSubTableVolumeGroupHeader_storageCost:"$ Storage",
  ebsSubTableVolumeGroupHeader_storageUsageBytes:"Storage Bytes",
  ebsSubTableVolumeGroupHeader_storageUsedBytes:"Storage Size",
  ebsSubTableVolumeGroupHeader_totalCost:"$ Total",
  ebsSubTableVolumeGroupHeader_totalReadTime:"Read (Seconds)",
  ebsSubTableVolumeGroupHeader_totalWriteTime:"Write (Seconds)",
  ebsSubTableVolumeGroupHeader_type: "Category",
  ebsSubTableVolumeGroupHeader_volumeId:"Volume Name",
  ebsSubTableVolumeGroupHeader_volumeReadOps:"Read IOPS",
  ebsSubTableVolumeGroupHeader_volumeWriteOps:"Write IOPS",
  ebsTableGroupHeader_billPayerAccountId:"Payer Account",
  ebsTableGroupHeader_operationsCost:"$ Operations",
  ebsTableGroupHeader_region:"Region",
  ebsTableGroupHeader_snapshotCost:"$ Snapshot",
  ebsTableGroupHeader_snapshotCount:"Snapshot count",
  ebsTableGroupHeader_snapshotUsageBytes:"Snapshot Usage (avg)",
  ebsTableGroupHeader_storageCost:"$ Storage",
  ebsTableGroupHeader_storageUsageBytes:"Volume Usage (avg)",
  ebsTableGroupHeader_totalCost:"Cost $",
  ebsTableGroupHeader_volumeCount:"Volume count",
  elasticBlockStoreCloudTitle: "Amazon Elastic Block Store (EBS)",
  elasticComputeCloudTitle: "Amazon Elastic Compute Cloud (EC2)",
  gcpCloudStorageTitle: "Google Cloud Storage",
  gcpComputeChartOptionLabel_CATEGORY: "Category",
  gcpComputeChartOptionLabel_DISK: "Disk",
  gcpComputeChartOptionLabel_FAMILY: "Family",
  gcpComputeChartOptionLabel_MEMORY: "Memory",
  gcpComputeChartOptionLabel_NETWORK: "Network",
  gcpComputeChartOptionLabel_OPERATING_SYSTEM: "Premium OS",
  gcpComputeChartOptionLabel_PROJECT_ID: "Project ID",
  gcpComputeChartOptionLabel_REGION: "Region",
  gcpComputeChartOptionLabel_STORAGE_CLASS: "Storage Class",
  gcpComputeChartOptionLabel_STORAGE_TYPE: "Storage Type",
  gcpComputeChartOptionLabel_VCPU: "vCpu",
  gcpComputeCostReportSnapshotName: "Google Compute Engine Cost Chart",
  gcpComputeMeterCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  gcpComputeMeterForecastedSpendComparisonCaption: "compared to last month",
  gcpComputeMeterForecastedSpendLabel: "Forecasted Spend",
  gcpComputeMeterLastMonthTotalCostLabel:"Last Month Total Spend",
  gcpComputeMeterMTDSpendComparisonCaption: "vs. this time last month",
  gcpComputeMeterRecommendationsAverageCaption: "average savings",
  gcpComputeMeterRecommendationsLabel: "Total Current Recommendations",
  gcpComputeOptimizationsChartTitleCost: "Aggregate Costs",
  gcpComputeOptimizationsChartTitleUsage: "Usage",
  gcpComputeTableInstanceGroupConfigureBillingTooltip: "Please enable the Detailed Usage and Cost report in your GCP Billing Console to view instances. See our GCP onboarding guide %link% for details on how to enable it.",
  gcpComputeTableInstanceGroupConfigureBillingTooltipLink: "here",
  gcpComputeTableInstanceGroupHeader_buckets: "Buckets",
  gcpComputeTableInstanceGroupHeader_family: "Family",
  gcpComputeTableInstanceGroupHeader_familyType: "Type",
  gcpComputeTableInstanceGroupHeader_instanceIDCount: "Instance Count",
  gcpComputeTableInstanceGroupHeader_productMemoryBytes: "Provisioned RAM",
  gcpComputeTableInstanceGroupHeader_productVCPU: "Provisioned vCPU",
  gcpComputeTableInstanceGroupHeader_projectId: "Project",
  gcpComputeTableInstanceGroupHeader_provisionedDiskBytes: "Provisioned Disk Bytes",
  gcpComputeTableInstanceGroupHeader_region: "Region",
  gcpComputeTableInstanceGroupHeader_totalCost: "Cost",
  gcpComputeTableInstanceGroupViewInstancesTooltip: "View Instances",
  gcpComputeTableInstancesHeader_cpuUtilization: "vCPU Util.",
  gcpComputeTableInstancesHeader_diskUtilization: "Disk Util.",
  gcpComputeTableInstancesHeader_instanceId: "Resource ID",
  gcpComputeTableInstancesHeader_instanceName: "Name",
  gcpComputeTableInstancesHeader_instanceType: "Instance/SKU",
  gcpComputeTableInstancesHeader_productMemoryBytes: "Provisioned RAM",
  gcpComputeTableInstancesHeader_productVCPU: "vCPU",
  gcpComputeTableInstancesHeader_provisionedDiskBytes: "Provisioned Disk",
  gcpComputeTableInstancesHeader_ramUtilization: "RAM Util.",
  gcpComputeTableInstancesHeader_totalCost: "Cost",
  gcpComputeTableNull: "null",
  gcpComputeUsageReportSnapshotName: "Google Compute Engine Usage Chart",
  gcpKubernetesCloudTitle: "Google Kubernetes Engine (GKE)",
  gcpServicesListTitle: "GCP",
  gcpServicesListTitleNotConfigured: "GCP (Not Configured)",
  gcpSpannerMetersCurrentMonthTotalCostLabel: "Monthly Spend To Date",
  gcpSpannerMetersDBStorageAllocated: "Allocated",
  gcpSpannerMetersDBStorageUsageLabel: "DB Storage Usage",
  gcpSpannerMetersDBStorageUsed: "Used",
  gcpSpannerMetersForecastedSpendComparisonCaption: "compared to last month",
  gcpSpannerMetersForecastedSpendLabel: "Forecasted Spend",
  gcpSpannerMetersLastMonthTotalCostLabel: "Last Month Total Spend",
  gcpSpannerMetersMTDSpendComparisonCaption: "vs. this time last month",
  gcpStorageResourceTableBuckets: "Buckets",
  gcpStorageResourceTableEgress: "Egress",
  gcpStorageResourceTableHighCarbonToolTip: "Non carbon-neutral",
  gcpStorageResourceTableIngress: "Ingress",
  gcpStorageResourceTableLowCarbonToolTip: "Google reports this storage region as being carbon-neutral",
  gcpStorageResourceTableNetwork: "Network",
  gcpStorageResourceTableOperation: "Operations",
  gcpStorageResourceTableOther: "Other",
  gcpStorageResourceTableProcessing: "Processing",
  gcpStorageResourceTableProject: "Project",
  gcpStorageResourceTableRecommendation: "Recommendations",
  gcpStorageResourceTableRegion: "Region",
  gcpStorageResourceTableStorage: "Storage",
  gcpStorageSubTableBucketID: "Bucket ID",
  gcpStorageSubTableClass: "Class Designation",
  gcpStorageSubTableLifeCycle: "Lifecyle Policy",
  gcpStorageSubTableLifeCycleActionType: "Lifecycle Action Type:",
  gcpStorageSubTableLifeCycleAge: "Condtion Age:",
  gcpStorageSubTableLifeCyclePrefixes: "Matches Prefix:",
  gcpStorageSubTableLifeCycleStorageClass: "Maches StorageClass:",
  gcpStorageSubTableLifeCycleSuffixes: "Matches Suffix:",
  gcpStorageSubTableNone: "None",
  gcpStorageSubTableNoRules: "No Rules",
  gcpStorageSubTableRecommendationCount: "Recommendation (Count)",
  gcpStorageSubTableStorageGB: "Storage",
  gcpStorageSubTableTotalObj: "Total Objects",
  gcpStorageSubTableViewBuckets: "View Buckets",
  gcpStorageUsageMeterMTDCaption: "Change from this time last month",
  gcpStorageUsageMeterMTDLabel: "Total MTD Spend (Storage)",
  gcpStorageUsageMeterRecommendationsCaption: "Percent Assigned",
  gcpStorageUsageMeterRecommendationsLabel: "Total Current Recommendations",
  gcpStorageUsageMetersBucketLabel: "Distinct Buckets (last 24 hrs)",
  gkeAllClustersInSelectedNamespaceAndProjectLabel: "(All clusters in %namespace% and %project%)",
  gkeAllClustersInSelectedNamespaceLabel: "(All clusters in %namespace%)",
  gkeAllClustersInSelectedProjectLabel: "(All clusters in %project%)",
  gkeAllClustersLabel: "(All clusters)",
  gkeAllNamespacesInSelectedClusterAndProjectLabel: "(All namespaces in %cluster% and %project%)",
  gkeAllNamespacesInSelectedProjectLabel: "(All namespaces in %project%)",
  gkeAllNamespacesLabel: "(All namespaces)",
  gkeAllWorkloadsInSelectedNamespaceClusterAndProjectLabel: "(All workloads in %namespace%, %cluster%, and %project%)",
  gkeCostChartTitle: "Cost",
  gkeCostReportSnapshotName: "Google Kubernetes Engine Cost Chart",
  gkeDayCountLabel_FOURTEEN_DAYS: "14 Days",
  gkeDayCountLabel_SEVEN_DAYS: "7 Days",
  gkeDayCountLabel_THIRTY_DAYS: "30 Days",
  gkeGkeTitle: "Google Kubernetes Engine",
  gkeMetersBytesLabel: "Bytes",
  gkeMetersCoresLabel: "Cores",
  gkeMetersRequestedLabel: "Requested",
  gkeMetersTotalCostLabel: "Total Cost",
  gkeMetersTotalLabel: "Total",
  gkeMetersTotalWasteLabel: "Total Waste",
  gkeMetersUsedLabel: "Used",
  gkeOptimizationsTabLabel: "Optimizations",
  gkeProjectDropdownAllProjectsLabel: "All Projects",
  gkeResourceListHeaderCPUCost: "CPU Cost",
  gkeResourceListHeaderCPUUtilization: "CPU Utilization",
  gkeResourceListHeaderMemoryCost: "Memory Cost",
  gkeResourceListHeaderMemoryUtilization: "Memory Utilization",
  gkeResourceListHeaderName: "Name",
  gkeResourceListHeaderProjectID: "Project ID",
  gkeResourceListHeaderTotalBytes: "Total Bytes",
  gkeResourceListHeaderTotalCores: "Total Cores",
  gkeResourceListHeaderTotalRequestedBytes: "Total Requested Bytes",
  gkeResourceListHeaderTotalRequestedCores: "Total Requested Cores",
  gkeResourceListHeaderUsedBytes: "Used Bytes",
  gkeResourceListHeaderUsedCores: "Used Cores",
  gkeResourceListHeaderUsedOverRequestedBytes: "Used / Requested Bytes",
  gkeResourceListHeaderUsedOverRequestedCores: "Used / Requested Cores",
  gkeResourceListHeaderUsedOverTotalBytes: "Used / Total Bytes",
  gkeResourceListHeaderUsedOverTotalCores: "Used / Total Cores",
  gkeResourceListHeaderWaste: "Total Waste",
  gkeResourceListLabelNotAvailable: "N/A",
  gkeResourceListTooltipLabelViewCluster: "View Cluster",
  gkeResourceListTooltipLabelViewClusters: "View Clusters",
  gkeResourceListTooltipLabelViewNamespace: "View Namespace",
  gkeResourceListTooltipLabelViewNamespaces: "View Namespaces",
  gkeResourceListTooltipLabelViewWorkload: "View Workload",
  gkeResourceListTooltipLabelViewWorkloads: "View Workloads",
  gkeSelectedClusterInSelectedNamespaceAndProjectLabel: "(%cluster% in %namespace% and %project%)",
  gkeSelectedClusterInSelectedProjectLabel: "(%cluster% in %project%)",
  gkeSelectedNamespaceInSelectedClusterAndProjectLabel: "(%namespace% in %cluster% and %project%)",
  gkeSelectedNamespaceInSelectedProjectLabel: "(%namespace% in %project%)",
  gkeSelectedNamespaceLabel: "(%namespace%)",
  gkeSelectedWorkloadInSelectedNamespaceClusterAndProjectLabel: "(%workload% in %namespace%, %cluster%, and %project%)",
  gkeTextInputLabel: "Search",
  gkeUsageChartTitle: "Usage",
  gkeUsageReportSnapshotName: "Google Kubernetes Engine Usage Chart",
  gkeUsageResourceLabelAllClusters: "View all clusters",
  gkeUsageResourceLabelAllNamespaces: "View all namespaces",
  gkeUsageResourceLabelClusters: "Clusters",
  gkeUsageResourceLabelNamespaces: "Namespaces",
  gkeUsageResourceLabelWorkloads: "Workloads",
  gkeUsageTooltipRequestedBytesLabel: "Requested Bytes",
  gkeUsageTooltipRequestedCoresLabel: "Requested Cores",
  gkeUsageTooltipTotalBytesLabel: "Total Bytes",
  gkeUsageTooltipTotalCoresLabel: "Total Cores",
  gkeUsageTooltipUsedBytesLabel: "Used Bytes",
  gkeUsageTooltipUsedCoresLabel: "Used Cores",
  gkeUsageTooltipWastedBytesLabel: "Wasted Bytes",
  gkeUsageTooltipWastedCoresLabel: "Wasted Cores",
  gkeUsageTypeLabelCPU: "CPU",
  gkeUsageTypeLabelMemory: "Memory",
  gkeUsageWastedBytesLabel: "Wasted Bytes",
  gkeUsageWastedCoresLabel: "Wasted Cores",
  gkeVisibilityTabLabel: "Visibility",
  granularityOptionDay: "Day (Avg)",
  granularityOptionHour: "Hour (Avg)",
  granularityOptionMonth: "Month",
  integrationNotConfiguredMessage: "Click |here| to configure your %provider% data integration.",
  kubernetesInsightsPageTitle: "Kubernetes Insights",
  monthlySpendComparisonCaption: "vs. this time last month",
  openSearchTitle: "Amazon OpenSearch Service",
  optimizationsTabLabel: "Optimizations",
  queryOptionLabelBlockStorageClass: "Block Storage Class",
  queryOptionLabelBlockStorageType: "Block Storage Type",
  queryOptionLabelCategory: "Category",
  queryOptionLabelComputeVSStorage: "CPU/RAM vs Block Storage",
  queryOptionLabelCPUDiscount: "CPU Discount",
  queryOptionLabelDayOfWeek: "Day Of Week (Avg)",
  queryOptionLabelHourOfDay: "Hour Of Day (Avg)",
  queryOptionLabelOperatingSystemFees: "Operating System Fees",
  queryOptionLabelRAMDiscount: "RAM Discount",
  rdsTitle: "Amazon Relational Database Service (RDS)",
  recommendationAddExternalTrackingLink: "Add Link to External Tracker",
  recommendationApplicable: "Applicable",
  recommendationClosed: "Closed",
  recommendationDetailsCreateCaseButtonLabel: "Create Case",
  recommendationDetailsHeaderChangeStatus: "Change Status",
  recommendationDetailsHeaderCommentInput: "Add To Discussion:",
  recommendationDetailsHeaderLabels: "Labels",
  recommendationDetailsHeaderLinkedAccount: "Linked Account",
  recommendationDetailsHeaderMarkResolved: "Mark as Resolved",
  recommendationDetailsHeaderProject: "Project",
  recommendationDetailsHeaderResource: "Resource",
  recommendationDetailsPlaceholderSelectStatus: "Select Status",
  recommendationDetailsPlaceholderSelectUser: "Select User",
  recommendationDetailsSnoozeLabel: "Snooze Until:",
  recommendationDetailsSnoozeToggle: "Snooze? ",
  recommendationMeterFooterTitleChangeFromLastMonth: "Change from this time last month",
  recommendationMeterFooterTitlePercentAssigned: "Percent Assigned",
  recommendationMeterFooterTitleSavingsPercentOfSpend: "Percent of Last Month's Spend",
  recommendationMeterHeaderAnomaliesCount: "Total Current Anomalies",
  recommendationMeterHeaderRecommendationsCount: "Total Current Recommendations",
  recommendationMeterHeaderTitleSavings: "Estimated Monthly Savings",
  recommendationMeterHeaderTitleSpendDelta: "Total Spend Delta",
  recommendationMeterHeaderTotalMTDSpend: "Total MTD Spend",
  recommendationNotApplicable: "Not Applicable",
  recommendationOpen: "Open",
  recommendationsBreakdownMeterActiveTooltip: "Active includes New and Accepted Recommendations",
  recommendationsBreakdownMeterTitleCurrent: "Current Recommendations",
  recommendationsBreakdownMeterTitlePast: "Past Recommendations",
  recommendationsBreakdownMeterTitleTypeActive: "Active",
  recommendationsBreakdownMeterTitleTypeAsDesigned: "As Designed",
  recommendationsBreakdownMeterTitleTypeImplemented: "Implemented",
  recommendationsBreakdownMeterTitleTypeInaccurate: "Inaccurate",
  recommendationsBreakdownMeterTitleTypeNotApplicable: "Not Applicable",
  recommendationsBreakdownMeterTitleTypeSnoozed: "Snoozed",
  recommendationsBulkEditMulti: "Edit %COUNT% Recommendations",
  recommendationsBulkEditSingle: "Edit Recommendation",
  recommendationsBulkEditUnchanged: "Unchanged",
  recommendationsCSVTableHeaderResource: "Resource",
  recommendationsLabelsFilter: "Filter by Labels",
  recommendationsOpenFilter: "Filter by Open",
  recommendationsProjectFilter: "Filter by Project",
  recommendationsStatusFilter: "Filter by Status",
  recommendationsTableControlsSearchPlaceholder: "Search",
  recommendationsTableEmptyPlaceholderText: "No Matching Optimizations Found",
  recommendationsTableEmptyPlaceholderTextAnomalies: "No Matching Anomalies Found",
  recommendationsTableHeaderCaseID: "Open Case(s)",
  recommendationsTableHeaderDate: "Created On",
  recommendationsTableHeaderEstimateUSD: "Est. Monthly Savings",
  recommendationsTableHeaderId: "ID",
  recommendationsTableHeaderOpen: "Open",
  recommendationsTableHeaderProject: "Project",
  recommendationsTableHeaderResourceAndAccount: "Resource (and Account)",
  recommendationsTableHeaderResourceAndProject: "Resource (and Project)",
  recommendationsTableHeaderSpendDelta: "Spend Delta",
  recommendationsTableHeaderState: "Status",
  recommendationsTableHeaderTypeRecommendation: "Recommendation Type",
  recommendationsTableLinkToCaseTooltip: "Go to case #%caseNumber%: %caseName%",
  recommendationsTableStatsEntriesCount: "Entries:",
  recommendationsTableStatsSavings: "Savings:",
  recommendationStatusAccepted: "Accepted",
  recommendationStatusAsDesigned: "As Designed",
  recommendationStatusImplemented: "Implemented",
  recommendationStatusInaccurate: "Inaccurate",
  recommendationStatusNew: "New",
  recommendationStatusSnoozed: "Snoozed",
  recommendationTableHeaderSpendDelta: "Spend Delta",
  recommendationUnsnoozed: "Un-Snoozed",
  s3ChartOptionLabel_BUCKETS: "Buckets",
  s3ChartOptionLabel_DATA_TRANSFER: "Data Transfer",
  s3ChartOptionLabel_IOPS: "IOPS",
  s3ChartOptionLabel_LINKED_ACCOUNTS: "Linked Accounts",
  s3ChartOptionLabel_OPERATIONS: "Operations",
  s3ChartOptionLabel_REGION: "Region",
  s3ChartOptionLabel_STORAGE_CLASS: "Storage Class",
  s3ChartOptionLabel_STORAGE: "Storage",
  s3OptimizationsChartTitleCost: "Aggregate Costs",
  s3OptimizationsChartTitleUsage: "Usage",
  s3SubTableBucketGroupHeader_bucketName:"Bucket Name",
  s3SubTableBucketGroupHeader_bytesDownloaded:"Bytes Downloaded",
  s3SubTableBucketGroupHeader_bytesUploaded:"Bytes Uploaded",
  s3SubTableBucketGroupHeader_lineItemUsageAccountID:"Linked ID",
  s3SubTableBucketGroupHeader_networkCost:"$ Network",
  s3SubTableBucketGroupHeader_numberOfObjects:"Number of Objects",
  s3SubTableBucketGroupHeader_operationsCost:"$ Operations",
  s3SubTableBucketGroupHeader_requestCount:"Request Count",
  s3SubTableBucketGroupHeader_storageClass:"Storage Class",
  s3SubTableBucketGroupHeader_storageCost:"$ Storage",
  s3SubTableBucketGroupHeader_storageUsedBytes:"Storage Size",
  s3SubTableBucketGroupHeader_totalCost:"$ Cost",
  s3TableBucketGroupHeader_accountID:"Billpayer Account",
  s3TableBucketGroupHeader_bucketCount:"Bucket Count",
  s3TableBucketGroupHeader_networkCost:"Network",
  s3TableBucketGroupHeader_operationsCost:"Operations",
  s3TableBucketGroupHeader_region:"Region",
  s3TableBucketGroupHeader_storageCost:"Storage",
  s3TableBucketGroupHeader_totalCost:"$ Cost",
  s3TableNull: "null",
  s3TabOptimizations: "Optimizations",
  s3TabVisibility: "Visibility",
  simpleStorageServiceCloudTitle: "Amazon Simple Storage Service (S3)",
  snowflakeCSVExportTitle: "snowflake-warehouse-usage",
  snowflakeDatabaseAggregateCostChartTitle: "Aggregate Cost",
  snowflakeDatabaseByAcccountBytesLabel: "Bytes",
  snowflakeDatabaseByAccountChartTitle: "Databases by Account",
  snowflakeDatabaseByAccountCountLabel: "Count",
  snowflakeDatabaseMeterAvgDatabaseBytes: "Avg Database Bytes",
  snowflakeDatabaseMeterAvgFailsafeBytes: "Avg Failsafe Bytes",
  snowflakeDatabaseMeterLastMonthTotalSpend: "Last Month Total Spend",
  snowflakeDatabaseMeterMonthlySpendToDate: "Monthly Spend To Date",
  snowflakeDatabaseNoDataLabel: "No Aggregate Cost Data",
  snowflakeDatabasesLabel: "Snowflake Database",
  snowflakeDatabaseTableHeader_accountName: "Account Name",
  snowflakeDatabaseTableHeader_avgBytes: "Avg Bytes",
  snowflakeDatabaseTableHeader_avgFailsafeBytes: "Avg Failsafe Bytes",
  snowflakeDatabaseTableHeader_comment: "Comment",
  snowflakeDatabaseTableHeader_databaseID: "Database ID",
  snowflakeDatabaseTableHeader_databaseName: "Database Name",
  snowflakeDatabaseTableHeader_databaseOwner: "Database Owner",
  snowflakeDatabaseTableHeader_deletionTime: "Deleted",
  snowflakeDatabaseTableHeader_lastAltered: "Last Altered",
  snowflakeDatabaseTableHeader_retentionTime: "Retention Time",
  snowflakeDatabaseTitle: "Databases",
  snowflakeDataWarehousesTitle: "Snowflake Virtual Warehouses",
  snowflakeResourceTableHeader_accountName: "Account Name",
  snowflakeResourceTableHeader_cloudServices: "Cloud Services Credits",
  snowflakeResourceTableHeader_computeCredits: "Compute Credits",
  snowflakeResourceTableHeader_elapsedTime: "Total Run Time",
  snowflakeResourceTableHeader_totalCredits: "Total Credits Used",
  snowflakeResourceTableHeader_warehouseName: "Warehouse Name",
  snowflakeResourceTableHeader_warehouseSize: "Warehouse Size",
  snowflakeResourceTableHeader_warehouseType: "Warehouse Type",
  snowflakeSerivesListTitle: "Snowflake",
  snowflakeServicesListNotConfigured: "Snowflake (Not Configured)",
  snowflakeWarehoueComputeCreditsChartTitle: "Compute Credits",
  snowflakeWarehouseAggregateChartNoData: "No Credit Data to Display",
  snowflakeWarehouseAggregateCreditChartTitle: "Aggregate Credits",
  snowflakeWarehouseMeterForecastedSpend: "Forecasted Spend",
  snowflakeWarehouseMeterForecastedSpendLabel: "Forecasted Spend",
  snowflakeWarehouseMeterLastMonthTotalCreditLabel: "Last Month Total Credit",
  snowflakeWarehouseMeterMonthlyCreditToDateLabel: "Monthly Credit To Date",
  snowflakeWarehouseMeterMontlyhSpendToDateLabel: "Monthly Spend To Date",
  snowflakeWarehouseMeterMSTD: "Montly Spend To Date",
  snowflakeWarehousesTitle: "Virtual Warehouses",
  snowflakeWarehouseTableHeader_elapsedTime: "Total Run Time",
  snowflakeWarehouseTableHeader_ID: "Warehouse ID",
  snowflakeWarehouseTableHeader_name: "Name",
  snowflakeWarehouseTableHeader_queries: "Queries",
  snowflakeWarehouseTableHeader_totalCredits: "Total Credits Used",
  snowflakeWarehouseTableViewInstancesTooltip: "View Instances",
  spannerOptimizationsTabLabel: "Optimizations",
  spannerOptimizationsTabMessage: "Optimizations Coming Soon",
  spannerTitle: "Google Cloud Spanner",
  spannerVisibilityTabLabel: "Visibility",
  storageAggregateCostLabel: "UNASSIGNED",
  storageCostAggregateChartTitle: "Aggregate Costs",
  storageCostEmptyPlaceholderText: "No Cost Data to Display",
  storageDimensionGroupingLabelAll: "All Buckets",
  storageDimensionGroupingLabelProjectID: "Project ID",
  storageDimensionGroupingLabelRegion: "Region",
  storageDimensionGroupingLabelStorageClass: "StorageClass",
  storageResourceTableBuckets: "Buckets",
  storageResourceTableEgress: "Egress",
  storageResourceTableHighCarbonToolTip: "Non carbon-neutral",
  storageResourceTableIngress: "Ingress",
  storageResourceTableLowCarbonToolTip: "Google reports this storage region as being carbon-neutral",
  storageResourceTableNetwork: "Network",
  storageResourceTableOperation: "Operations",
  storageResourceTableOther: "Other",
  storageResourceTableProcessing: "Processing",
  storageResourceTableProject: "Project",
  storageResourceTableRecommendation: "Recommendations",
  storageResourceTableRegion: "Region",
  storageResourceTableStorage: "Storage",
  storageResourceUsageReserved: "reserved",
  storageResourceUsageUsed: "used",
  storageServiceCloudTitle: "Cloud Storage",
  storageSubTableViewBuckets: "View Buckets",
  storageSubTableViewSnapshots: "View Snapshots",
  storageSubTableViewVolumes: "View Volumes",
  storageUsageAboveTableBar: "Buckets",
  storageUsageAboveTableFilter: "Filters:",
  storageUsageBreadCrumb: "All Storage",
  storageUsageChartTitle: "Usage",
  storageUsageTypeLabelDataTransfer: "Data Transfer",
  storageUsageTypeLabelOperation: "Operations",
  storageUsageTypeLabelStorage: "Storage",
  totalRecommendationsMeterTitle: "Total Recommendations",
  virtualWarehousesLabel: "Virtual Warehouses",
  visibilityTabLabel: "Visibility",
};
